import { styled } from "@mui/material/styles";
import { useState } from "react";
import { FileDrop } from "react-file-drop";
import { useDispatch } from "react-redux";
import { Box, Button, CloudUploadIcon, Divider, Typography } from "../../mui";
import { snackbarActions } from "../../Store/snackbar";

const fileTypeMapping = {
  csv: "text/csv",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xml: "text/xml",
  xls: "application/vnd.ms-excel",
};

const CustomizedFileDrop = styled(FileDrop)(({ theme }) => ({
  flex: 1,
  "& .file-drop-target": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FileUpload = ({ multi, files, setFiles, accept, disabled }) => {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);

  const validTypes = accept.map((key) => {
    return fileTypeMapping[key];
  });
  console.log(validTypes);

  const handleFileDrop = (files, event) => {
    event.preventDefault();
    const filesValidation = files[0];
    console.log(filesValidation);
    if (!validTypes.includes(filesValidation.type)) {
      console.log(filesValidation.type);
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Invalid File Format, must be of type: ${accept.join(
            ", "
          )}`,
        })
      );
      return;
    }
    setFiles(files[0]);
  };

  const handleFileUpload = (event) => {
    const filesValidation = event.target.files[0];
    if (!validTypes.includes(filesValidation.type)) {
      console.log("Invalid Type");
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Invalid File Format, must be of type: ${accept.join(
            ","
          )}`,
        })
      );
      return;
    }
    setFiles(event.target.files[0]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: hover ? "rgb(200,216,280,0.6)" : "rgb(216,229,242,0.5)",
        borderRadius: "5px",
      }}
    >
      <CustomizedFileDrop
        onFrameDragEnter={(event) => setHover(true)}
        onFrameDragLeave={(event) => setHover(false)}
        onFrameDrop={(event) => setHover(false)}
        onDragOver={(event) => {}}
        onDragLeave={(event) => {}}
        onDrop={(files, event) => handleFileDrop(files, event)}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloudUploadIcon
            sx={{
              mt: 2,
              fontSize: 60,
              color: "secondary.light",
            }}
          />
          <Typography
            component="div"
            role="status"
            tabIndex={0}
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              mb: 1,
              padding: "4px 8px",
              borderRadius: "4px",
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? "#ffffff" // Pure white text for maximum contrast
                  : "rgba(0, 0, 0, 0.87)",
              // Add a subtle text shadow in dark mode for better readability
              textShadow: (theme) =>
                theme.palette.mode === "dark"
                  ? "0px 1px 2px rgba(0, 0, 0, 0.5)"
                  : "none",
            }}
            aria-label="Just Drag and Drop"
          >
            Just Drag and Drop
          </Typography>
          <Divider flexItem>
            <Typography
              component="div"
              role="status"
              tabIndex={0}
              sx={{
                fontSize: "1rem",
                fontWeight: 500,
                mb: 1,
                padding: "4px 8px",
                borderRadius: "4px",
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "#ffffff" // Pure white text for maximum contrast
                    : "rgba(0, 0, 0, 0.87)",
                // Add a subtle text shadow in dark mode for better readability
                textShadow: (theme) =>
                  theme.palette.mode === "dark"
                    ? "0px 1px 2px rgba(0, 0, 0, 0.5)"
                    : "none",
              }}
              aria-label="or"
            >
              or
            </Typography>
          </Divider>
          <Button
            // sx={{
            //   mb: 2.5,
            //   mt: 1,
            // }}
            // component="label"
            // variant="contained"
            // color="blue"
            // disabled={disabled}

            sx={{
              mb: 2.5,
              mt: 1,
              display: "inline-block",
              opacity: disabled ? 0.7 : 1,
              cursor: disabled ? "not-allowed" : "pointer",
              backgroundColor: disabled
                ? "rgba(0, 0, 0, 0.12)"
                : "primary.main",
              color: disabled ? "rgba(0, 0, 0, 0.26)" : "#fff",
              "&:hover": {
                backgroundColor: disabled
                  ? "rgba(0, 0, 0, 0.12)"
                  : "primary.dark",
              },
              "&:focus": {
                outline: "2px solid",
                outlineColor: "primary.main",
                outlineOffset: "2px",
              },
            }}
            onClick={() => {
              if (!disabled) {
                document.getElementById("file-upload-input").click();
              }
            }}
            onKeyDown={(e) => {
              if ((e.key === "Enter" || e.key === " ") && !disabled) {
                e.preventDefault();
                document.getElementById("file-upload-input").click();
              }
            }}
            variant="contained"
            tabIndex={0}
            aria-disabled={disabled}
            aria-label={
              disabled
                ? "Choose a file - Select project first"
                : "Choose a file"
            }
          >
            Choose a File
            {/* <input
              type="file"
              name="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
            /> */}
            <input
              id="file-upload-input"
              type="file"
              name="file"
              accept=".csv"
              hidden
              onChange={handleFileUpload}
              aria-hidden="true"
              tabIndex={-1}
            />
          </Button>
        </Box>
      </CustomizedFileDrop>
    </Box>
  );
};

export default FileUpload;
