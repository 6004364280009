import { Button, Typography } from "@mui/material";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { useState, useEffect } from "react";
import ManagementService from "../../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../../Store/snackbar";
import { Box, IconButton } from "../../../mui";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AF2Active = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const inProgress = "in_progress";
  const rejected = "rejected";
  const accept = "Accept";

  // open and close popup for confirmation on Accept or Reject
  const handleOpenModal = (actionType, params) => {
    setTitle(actionType);
    setData(params.row);
    setConfirmation(true);
  };

  // renders buttons inside action colunms for DataGrid
  const renderDetailsButton = (params) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleOpenModal(accept, params)}
          disabled={params.row.file_status === inProgress}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              handleOpenModal(accept, params);
            }
          }}
          tabIndex={0}
          aria-label={`Accept ${params.row.file_name}`}
        >
          {accept}
        </Button>

        <Button
          variant="outlined"
          onClick={() => handleOpenModal("Reject", params)}
          disabled={params.row.file_status === inProgress}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              handleOpenModal("Reject", params);
            }
          }}
          tabIndex={0}
          aria-label={`Reject ${params.row.file_name}`}
        >
          Reject
        </Button>
      </Box>
    );
  };

  const renderDownloadLinks = (params) => {
    const fileNameArray = params.value.split("/");
    const fileName = fileNameArray[fileNameArray.length - 1];
    return (
      <Typography
        variant="p"
        color="secondary.main"
        onClick={() => handleDownload(params.value)}
        title={fileName}
        sx={{
          cursor: "Pointer",
          textDecoration: "underline",
        }}
      >
        {fileName}
      </Typography>
    );
  };

  const handleDownload = (file) => {
    const fileName = file.split("/");
    ManagementService.getAF2File(fileName[fileName.length - 1])
      .then((resp) => {
        window.location.replace(resp.data.presigned_url);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Download",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Download",
          })
        );
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const AF2ActiveColumns = [
    {
      field: "file_name",
      headerName: "File Name",
      minWidth: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p" color="secondary.main" title={params.value}>
            {params.value}
          </Typography>
        );
      },

      valueGetter: (params) => {
        return params ?? "None/Null";
      },
    },
    {
      field: "file_date",
      headerName: "File Date",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
    },
    {
      field: "deltas_file",
      headerName: "Deltas File",
      minWidth: 230,
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
      renderCell: renderDownloadLinks,
    },
    {
      field: "deltas_summary_file",
      headerName: "Deltas Summary",
      minWidth: 250,
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
      renderCell: renderDownloadLinks,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
      renderCell: renderDetailsButton,
    },
  ];

  useEffect(() => {
    getAF2FileRecords();
    return;
  }, []);

  // get all records of AF2 Files
  const getAF2FileRecords = async () => {
    setDataLoading(true);
    await ManagementService.getAF2FileRecords()
      .then((resp) => {
        const fiterData = resp.data.filter(
          (row) =>
            row.file_status === "pending" || row.file_status === inProgress
        );
        setTableData(
          fiterData.map((row, id) => {
            return { id, ...row };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to get Samples Pending Accessioning.",
          })
        );
      });
    setDataLoading(false);
  };

  // Action Accept or Reject function
  const onAccetRejectAction = async () => {
    let body = {};
    setDataLoading(true);
    const fileStatus = title === accept ? inProgress : rejected;
    if (fileStatus === rejected) {
      const deltasFile = data.deltas_file.split("/").pop();
      const deltasSummaryFile = data.deltas_summary_file.split("/").pop();
      body.file_name = data.file_name;
      body.file_status = fileStatus;
      body.deltas_file = deltasFile;
      body.deltas_summary_file = deltasSummaryFile;
    } else {
      body.file_name = data.file_name;
      body.file_status = fileStatus;
    }
    ManagementService.acceptOrRejectAF2(body)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setConfirmation(false);
        getAF2FileRecords();
      })
      .catch((err) => {
        setConfirmation(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setDataLoading(false);
  };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
    const rowCount = apiRef.current.getRowsCount();

    const theme = useTheme();
    // Calculate current range of visible rows
    const startRow = page * pageSize + 1;
    const endRow = Math.min((page + 1) * pageSize, rowCount);

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "8px 16px",
          width: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <label
            id="rows-per-page-label"
            htmlFor="rows-per-page-select"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '0.8rem',
              fontWeight: '400',
              lineHeight: '1.43',
              margin: '0',
              padding: '0'
            }}
          >
            <span
              tabIndex={0}
              role="status"
              aria-label="Rows per page selector"
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                cursor: 'default',
                color: theme.palette.mode === "dark" ? theme.palette.buttonDefault.main : theme.palette.black.main,
              }}
              sx={{
                '&:focus': {
                  outline: '2px solid #9b51e0',
                  outlineOffset: '2px',
                  backgroundColor: 'rgba(155, 81, 224, 0.08)'
                },
                '&:focus-visible': {
                  outline: '2px solid #9b51e0',
                  outlineOffset: '2px',
                  backgroundColor: 'rgba(155, 81, 224, 0.08)'
                }
              }}
            >
              Rows per page:
            </span>
          </label>
          <Select
            id="rows-per-page-select"
            value={pageSize}
            onChange={(e) => apiRef.current.setPageSize(Number(e.target.value))}
            labelId="rows-per-page-label"
            aria-labelledby="rows-per-page-label"
            inputProps={{
              'aria-label': 'Rows per page'
            }}
            sx={{
              marginLeft: '8px',
              minWidth: 65,
              '& .MuiSelect-select': {
                padding: '4px 8px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:focus': {
                outline: (theme) =>
                  `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '2px'
              }
            }}
          >
            {[25, 50, 100].map((size) => (
              <MenuItem
                key={size}
                value={size}
              >
                {size}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography
          variant="body2"
          tabIndex={0}
          sx={{ mx: 2, ml: 2 }}
          role="status"
          aria-live="polite"
        >
          {startRow}-{endRow} of {rowCount}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              if (page > 0) {
                apiRef.current.setPage(page - 1);
              }
            }}
            // Remove the disabled prop to keep it focusable
            // disabled={page === 0}
            size="small"
            aria-label="Previous page"
            // Always keep it in the tab order
            tabIndex={0}
            // Use aria-disabled for accessibility
            aria-disabled={page === 0}
            onKeyDown={(e) => {
              // Allow keyboard activation with Enter or Space
              if ((e.key === "Enter" || e.key === " ") && page > 0) {
                e.preventDefault();
                apiRef.current.setPage(page - 1);
              }
            }}
            sx={{
              "&:focus": {
                outline: "none",
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
              },
              '&[aria-disabled="true"]': {
                color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                cursor: "default",
              },
              '&[aria-disabled="false"]': {
                color: "inherit",
              },
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>

          <IconButton
            onClick={() => {
              if (page < pageCount - 1) {
                apiRef.current.setPage(page + 1);
              }
            }}
            aria-disabled={page >= pageCount - 1}
            size="small"
            aria-label="Next page"
            tabIndex={0}
            sx={{
              "&:focus": {
                outline: "none",
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
              },
              '&[aria-disabled="true"]': {
                color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
              },
              '&[aria-disabled="false"]': {
                color: "inherit",
              },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box >
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Total Number of Records: {tableData.length}
        </Typography>

        <DataGrid
          rows={tableData}
          columns={AF2ActiveColumns}
          loading={dataLoading}
          density="compact"
          disableSelectionOnClick
          autosizeOptions={{
            columns: ["file_name", "deltas_file", "deltas_summary_file"],
            includeOutliers: true,
            includeHeaders: false,
          }}
          slots={{
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            cell: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
            columnHeader: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
            "& .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
          }}
        />
      </Box>

      <ConfirmationDialog
        open={confirmation}
        handleClose={() => setConfirmation(false)}
        handleConfirm={onAccetRejectAction}
        title="Data Updation"
        subTitle={"Are you sure you want to " + title + " ?"}
      />
    </>
  );
};

export default AF2Active;
