import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ConfirmationDialog = ({ open, handleClose, handleConfirm, title, subTitle }) => {

  return (
    <div
      role="presentation"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1300,
        display: open ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={handleClose}
    >
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          outline: 'none',
          width: "400px",
          maxWidth: "90%"
        }}
        role="dialog"
        aria-modal="true"
        aria-labelledby="confirmation-dialog-title"
        onClick={e => e.stopPropagation()}
      >
        <Typography
          variant="h6"
          component="h2"
          id="confirmation-dialog-title"
          sx={{ mb: 2 }}
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 3 }}
        >
          {subTitle}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 2
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
          >
            Back
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default ConfirmationDialog;
