import { useState, useEffect } from "react";
import ManagementService from "../../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../../Store/snackbar";
import { Box, IconButton } from "../../../mui";
import { visuallyHidden } from "@mui/utils";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,

} from "@mui/x-data-grid";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AF2History = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const dispatch = useDispatch();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  // columns configuration for DataGrid Table
  const AF2ActiveColumns = [
    {
      field: "file_name",
      headerName: "File Name",
      minWidth: 200,
      editable: false,
      valueGetter: (params) => {
        // if (!params.value) {
        //   return "None/Null";
        // }
        // return params.value;
        return params ?? "None/Null";
      },
    },
    {
      field: "file_date",
      headerName: "File Date",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        // if (!params.value) {
        //   return "None/Null";
        // }
        // return params.value;
        return params ?? "None/Null";
      },
    },
    {
      field: "deltas_file",
      headerName: "Deltas File",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        const fileNameArray = params.split("/");
        const fileName = fileNameArray[fileNameArray.length - 1];
        return fileName ?? "None/Null";
      },
    },
    {
      field: "deltas_summary_file",
      headerName: "Deltas Summary",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        const fileNameArray = params.split("/");
        const fileName = fileNameArray[fileNameArray.length - 1];
        return fileName ?? "None/Null";
      },
    },
    {
      field: "file_status",
      headerName: "Status",
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
    },
    {
      field: "status_update_date",
      headerName: "Accepted/Rejected Date",
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
    },
    {
      field: "action_by",
      headerName: "Action",
      minWidth: 100,
      editable: false,
      valueGetter: (params) => {
        return params ?? "None/Null";
      },
    },
  ];

  useEffect(() => {
    getAF2FileRecords();
    return;
  }, []);

  const getAF2FileRecords = async () => {
    setDataLoading(true);
    await ManagementService.getAF2FileRecords()
      .then((resp) => {
        const fiterData = resp.data.filter(
          (row) =>
            row.file_status === "accepted" ||
            row.file_status === "rejected" ||
            row.file_status === "error"
        );
        setTableData(
          fiterData.map((row, id) => {
            return { id, ...row };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to get Samples Pending Accessioning.",
          })
        );
      });
    setDataLoading(false);
  };


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
    const rowCount = apiRef.current.getRowsCount();
    const theme = useTheme();
    // Calculate current range of visible rows
    const startRow = page * pageSize + 1;
    const endRow = Math.min((page + 1) * pageSize, rowCount);

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "8px 16px",

        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <label
            id="rows-per-page-label"
            htmlFor="rows-per-page-select"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '0.8rem',
              fontWeight: '400',
              lineHeight: '1.43',
              margin: '0',
              padding: '0'
            }}
          >
            <span
              tabIndex={0}
              role="status"
              aria-label="Rows per page selector"
              style={{
                padding: '4px 8px',
                borderRadius: '4px',
                cursor: 'default',
                color: theme.palette.mode === "dark" ? theme.palette.buttonDefault.main : theme.palette.black.main,
              }}
              sx={{
                '&:focus': {
                  outline: '2px solid #9b51e0',
                  outlineOffset: '2px',
                  backgroundColor: 'rgba(155, 81, 224, 0.08)'
                },
                '&:focus-visible': {
                  outline: '2px solid #9b51e0',
                  outlineOffset: '2px',
                  backgroundColor: 'rgba(155, 81, 224, 0.08)'
                }
              }}
            >
              Rows per page:
            </span>
          </label>
          <Select
            id="rows-per-page-select"
            value={pageSize}
            onChange={(e) => apiRef.current.setPageSize(Number(e.target.value))}
            labelId="rows-per-page-label"
            aria-labelledby="rows-per-page-label"
            inputProps={{
              'aria-label': 'Rows per page'
            }}
            sx={{
              marginLeft: '8px',
              minWidth: 65,
              '& .MuiSelect-select': {
                padding: '4px 8px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:focus': {
                outline: (theme) =>
                  `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '2px'
              }
            }}
          >
            {[25, 50, 100].map((size) => (
              <MenuItem
                key={size}
                value={size}
              >
                {size}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography
          variant="body2"
          tabIndex={0}
          sx={{ mx: 2, ml: 2 }}
          role="status"
          aria-live="polite"
        >
          {startRow}-{endRow} of {rowCount}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              if (page > 0) {
                apiRef.current.setPage(page - 1);
              }
            }}
            // Remove the disabled prop to keep it focusable
            // disabled={page === 0}
            size="small"
            aria-label="Previous page"
            // Always keep it in the tab order
            tabIndex={0}
            // Use aria-disabled for accessibility
            aria-disabled={page === 0}
            onKeyDown={(e) => {
              // Allow keyboard activation with Enter or Space
              if ((e.key === "Enter" || e.key === " ") && page > 0) {
                e.preventDefault();
                apiRef.current.setPage(page - 1);
              }
            }}
            sx={{
              "&:focus": {
                outline: "none",
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
              },
              '&[aria-disabled="true"]': {
                color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                cursor: "default",
              },
              '&[aria-disabled="false"]': {
                color: "inherit",
              },
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>

          <IconButton
            onClick={() => {
              if (page < pageCount - 1) {
                apiRef.current.setPage(page + 1);
              }
            }}
            aria-disabled={page >= pageCount - 1}
            size="small"
            aria-label="Next page"
            tabIndex={0}
            sx={{
              "&:focus": {
                outline: "none",
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
              },
              '&[aria-disabled="true"]': {
                color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
              },
              '&[aria-disabled="false"]': {
                color: "inherit",
              },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <h1 style={visuallyHidden}>Welcome to Sampled Sphere</h1>
      {/* <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      > */}
      <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
        Total Number of Records: {tableData.length}
      </Typography>

      <DataGrid
        rows={tableData}
        columns={AF2ActiveColumns}
        getCellClassName={(params) => {
          if (params.field === "file_status") {
            return params.value;
          }
        }}
        loading={dataLoading}
        density="compact"
        disableSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
          pagination: CustomPagination,
        }}
        slotProps={{
          cell: {
            tabIndex: 0,
            onKeyDown: (e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                e.currentTarget.click();
              }
            },
          },
          columnHeader: {
            tabIndex: 0,
            onKeyDown: (e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                e.currentTarget.click();
              }
            },
          },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cell': {
            '&:focus': {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: '-2px',
            },
          },
          '& .MuiDataGrid-columnHeader': {
            '&:focus': {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: '-2px',
            },
          },
        }}

      />

      {/* </Box> */}
    </>
  );
};

export default AF2History;
