import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  CancelIcon,
  IconButton,

} from "../../mui";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Grid } from "@mui/material";
import ManagementService from "../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { isEqual } from "lodash";
import { visuallyHidden } from "@mui/utils";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const tableRowData = [
  "Tracking Number",
  "Client",
  "Number of Samples",
  "Created By",
];

const DeleteManifest = (props) => {
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [trackingNumberList, setTrackingNumberList] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(10);
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [subTitleText, setSubTitleText] = useState("");
  const theme = useTheme();

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  const addToList = async () => {
    await ManagementService.getManifestTrackingNumbers(
      client,
      project,
      trackingNumber
    )
      .then((resp) => {
        if (resp.data.length !== 0)
          resp.data.forEach((element) => {
            if (tableData.filter((v) => isEqual(v, element)).length === 0)
              setTableData((prev) => [...prev, element]);
          });
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });

    setTrackingNumber("");
  };

  const handleRemoveCell = (row) => {
    setTableData((prev) =>
      prev.filter(
        (item) =>
          row.tracking_number !== item.tracking_number ||
          row.created_by !== item.created_by
      )
    );
  };
  const handleShowConfirmation = () => {
    setConfirmation(true);
    const warningText = tableData
      .map((item) => item.tracking_number)
      .join(", ");
    setSubTitleText(warningText);
  };

  const submitData = async () => {
    setDataLoading(true);
    await ManagementService.deleteAccessionManifest(tableData)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setDataLoading(false);
  };
  const clearForm = () => {
    props.setOpenServiceModal(false);
    props.setRefresh((prev) => !prev);
    setTableData([]);
  };

  const handleClose = () => {
    props.setOpenServiceModal(false);
    setTableData([]);
  };

  useEffect(() => {
    setTrackingNumber("");
    if (client && project) {
      ManagementService.getTrackingNumbers(client, project)
        .then((resp) => {
          setTrackingNumberList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client, project]);

  useEffect(() => {
    setProject("");
    if (client) {
      ManagementService.getClientProjects(client)
        .then((resp) => {
          setProjectList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client]);

  useEffect(() => {
    ManagementService.getAllClients(client)
      .then((resp) => {
        setClientList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box>
      {props.openServiceModal && (
        <div
          role="presentation"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={handleClose}
        >
          <Box
            sx={{
              position: 'relative',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              outline: 'none',
              width: "70%",
              maxHeight: '90vh',
              overflow: 'auto'
            }}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
            onClick={e => e.stopPropagation()}
          >
            <Typography
              variant="h4"
              component="div"
              role="button"
              tabIndex={0}
              id="modal-title"
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                lineHeight: "116.7%",
                letterSpacing: "-1.5px",
              }}
              color="secondary"
            >
              Select Tracking Numbers
            </Typography>

            <Divider style={{ width: "100%" }}></Divider>
            <Stack direction="row" style={{ width: "100%" }}>
              <Autocomplete
                id="tags-standard"
                freeSolo
                inputValue={client || ""}
                options={clientList}
                onInputChange={(e, newValue) => setClient(newValue)}
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                style={{ width: "30%", margin: "15px 5px 5px 5px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    id="standard-multiline-flexible"
                    size="small"
                    type="text"
                    name={"Client"}
                    label={"Client"}
                  />
                )}
              />
              <Autocomplete
                id="tags-standard"
                freeSolo
                inputValue={project || ""}
                aria-disabled={!client}
                options={client ? projectList : []}
                onInputChange={(e, newValue) => {
                  if (!client) return;
                  setProject(newValue);
                }}
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                style={{ width: "30%", margin: "15px 5px 5px 5px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    id="standard-multiline-flexible"
                    size="small"
                    type="text"
                    name={"Project"}
                    label={"Project"}

                    sx={{
                      "& .MuiFormControl-root": {
                        outline: "none !important",
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
                        "&.Mui-focused": {
                          color: 'primary.main'
                        }
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
                        opacity: 1,
                      },
                      // Add styles for disabled state
                      "&[aria-disabled='true']": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        "& .MuiInputBase-root": {
                          color: 'rgba(0, 0, 0, 0.26)',
                          borderColor: 'rgba(0, 0, 0, 0.12)',

                        },
                        "& .MuiInputLabel-root": {
                          color: 'rgba(0, 0, 0, 0.26)',
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: 'rgba(0, 0, 0, 0.12)',
                        }
                      },
                      // Add styles for focused state
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: 'primary.main',
                        borderWidth: 2,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disabled: !client
                    }}
                  />
                )}
              />

              <Autocomplete
                id="tags-standard"
                freeSolo
                inputValue={trackingNumber || ""}
                aria-disabled={!(client && project)}
                options={(client && project) ? trackingNumberList : []}
                onInputChange={(e, newValue) => {
                  if (!(client && project)) return;
                  setTrackingNumber(newValue);
                }}
                getOptionLabel={(option) =>
                  typeof option === "string" || option instanceof String
                    ? option
                    : ""
                }
                style={{ width: "30%", margin: "15px 5px 5px 5px" }}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    id="standard-multiline-flexible"
                    size="small"
                    type="text"
                    name={"Tracking Number"}
                    label={"Tracking Number"}
                    sx={{
                      "& .MuiFormControl-root": {
                        outline: "none !important",
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
                        "&.Mui-focused": {
                          color: 'primary.main'
                        }
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
                        opacity: 1,
                      },
                      "&[aria-disabled='true']": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        "& .MuiInputBase-root": {
                          color: 'rgba(0, 0, 0, 0.26)',
                          borderColor: 'rgba(0, 0, 0, 0.12)',
                          pointerEvents: 'none',
                        },
                        "& .MuiInputLabel-root": {
                          color: 'rgba(0, 0, 0, 0.26)',
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: 'rgba(0, 0, 0, 0.12)',
                        }
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: 'primary.main',
                        borderWidth: 2,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disabled: !(client && project)
                    }}
                  />
                )}
              />
              <Button
                aria-disabled={!trackingNumber}
                type="submit"
                variant="outlined"
                role="button"
                tabIndex={0}
                onClick={(e) => {
                  if (!trackingNumber) {
                    e.preventDefault();
                    return;
                  }
                  addToList();
                }}
                aria-label={
                  !trackingNumber
                    ? "Add to list - Enter tracking number first"
                    : "Add tracking number to list"
                }
                sx={{
                  width: "10%", margin: "15px 5px 5px 5px",
                  '&[aria-disabled="true"]': {
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                    color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                    borderColor: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',

                  },
                  '&:not([aria-disabled="true"])': {
                    cursor: 'pointer',
                  },
                  '&:focus': {
                    outline: '2px solid',
                    outlineColor: 'primary.main',
                    outlineOffset: '2px',
                  },
                  '&:focus:not(:focus-visible)': {
                    outline: 'none'
                  },
                  '&.Mui-focusVisible': {
                    outline: '2px solid',
                    outlineColor: 'primary.main',
                    outlineOffset: '2px',
                  }
                }}
              >
                Add
              </Button>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <Grid
                // container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {tableData && (
                  <TableContainer style={{ marginTop: "30px" }}>
                    <Table
                      stickyHeader={true}
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      size={"small"}
                    >
                      <TableHead sx={{ minWidth: 650 }}>
                        <TableRow sx={{ minWidth: 650 }}>
                          {tableRowData.map((row, index) => (
                            <TableCell
                              align="left"
                              key={index}
                              style={{ fontWeight: "bold" }}
                            >
                              {row}
                            </TableCell>
                          ))}
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          ><span style={visuallyHidden}>A</span></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ border: "none" }}>
                        {tableData !== 0 &&
                          tableData
                            .slice(
                              pageLarge * rowsPerPageLarge,
                              pageLarge * rowsPerPageLarge + rowsPerPageLarge
                            )
                            .map((row, index) => (
                              <TableRow
                                hover={true}
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  {row.tracking_number || "None / Null"}
                                </TableCell>
                                <TableCell align="left">
                                  {row.client || "None / Null"}
                                </TableCell>
                                <TableCell align="left">
                                  {row.number_of_samples || "None / Null"}
                                </TableCell>

                                <TableCell align="left">{row.created_by}</TableCell>
                                <TableCell
                                  align="left"
                                  onClick={(e) => handleRemoveCell(row)}
                                >
                                  <Tooltip
                                    title="Remove"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <CancelIcon color="secondary"></CancelIcon>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={tableData.length}
                      rowsPerPage={rowsPerPageLarge}
                      page={pageLarge}
                      onPageChange={handleChangePageLarge}
                      onRowsPerPageChange={handleChangeRowsPerPageLarge}
                      ActionsComponent={(props) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => {
                              if (props.page > 0) {
                                props.onPageChange(null, props.page - 1);
                              }
                            }}
                            aria-label="Previous page"
                            tabIndex={0}
                            aria-disabled={props.page === 0}
                            onKeyDown={(e) => {
                              if ((e.key === 'Enter' || e.key === ' ') && props.page > 0) {
                                e.preventDefault();
                                props.onPageChange(null, props.page - 1);
                              }
                            }}
                            sx={{
                              '&:focus': {
                                outline: 'none',
                                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
                              },
                              '&[aria-disabled="true"]': {
                                color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                                cursor: 'default',
                              },
                              '&[aria-disabled="false"]': {
                                color: 'inherit',
                              },
                            }}
                          >
                            <KeyboardArrowLeft />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (props.page < Math.ceil(props.count / props.rowsPerPage) - 1) {
                                props.onPageChange(null, props.page + 1);
                              }
                            }}
                            aria-label="Next page"
                            tabIndex={0}
                            aria-disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
                            onKeyDown={(e) => {
                              if ((e.key === 'Enter' || e.key === ' ') &&
                                props.page < Math.ceil(props.count / props.rowsPerPage) - 1) {
                                e.preventDefault();
                                props.onPageChange(null, props.page + 1);
                              }
                            }}
                            sx={{
                              '&:focus': {
                                outline: 'none',
                                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
                              },
                              '&[aria-disabled="true"]': {
                                color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                              },
                              '&[aria-disabled="false"]': {
                                color: 'inherit',
                              },
                            }}
                          >
                            <KeyboardArrowRight />
                          </IconButton>
                        </Box>
                      )}


                    />
                  </TableContainer>
                )}
              </Grid>
            </Box>
            <div className="modal-footer">
              <Stack direction="row" style={{ width: "100%" }}>
                <Button

                  aria-disabled={tableData.length === 0 || dataLoading}
                  type="submit"
                  variant="contained"
                  role="button"
                  tabIndex={0}
                  onClick={(e) => {
                    if (tableData.length === 0 || dataLoading) {
                      e.preventDefault();
                      return;
                    }
                    handleShowConfirmation();
                  }}

                  sx={{
                    width: "100%", margin: "15px 5px 5px 5px",
                    '&[aria-disabled="true"]': {
                      pointerEvents: 'none',
                      cursor: 'not-allowed',
                      color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
                      borderColor: 'rgba(0, 0, 0, 0.12)',
                      backgroundColor: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
                    },
                    '&:not([aria-disabled="true"])': {
                      cursor: 'pointer',
                    },
                    '&:focus': {
                      outline: '2px solid',
                      outlineColor: 'primary.main',
                      outlineOffset: '2px',
                    },
                    '&:focus:not(:focus-visible)': {
                      outline: 'none'
                    },
                    '&.Mui-focusVisible': {
                      outline: '2px solid',
                      outlineColor: 'primary.main',
                      outlineOffset: '2px',
                    }
                  }}

                >
                  Submit
                </Button>

              </Stack>
            </div>
          </Box>
        </div>
      )}

      <ConfirmationDialog
        open={confirmation}
        handleClose={() => {
          setConfirmation(false);
        }}
        handleConfirm={submitData}
        title="Final Warning: Once a manifest is deleted, It will need to be fully re-ingested.
        Tracking numbers shown below will get deleted permanently.
        Please verify before deleting, or press cancel."
        subTitle={subTitleText}
      />
    </Box>
  );
};

export default DeleteManifest;
