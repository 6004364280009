import { Divider, Typography } from "@mui/material";
import React from "react";
import { Stack } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const Header = (props) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            props.handleViewChange(props.header);
        }
    };
    return (
        <Stack>
            <Stack direction="row"
                onClick={() => props.handleViewChange(props.header)}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
                aria-expanded={props.selected ? 'true' : 'false'}>
                {props.selected ? (
                    <ExpandMoreIcon
                        fontSize="medium"
                        style={{
                            color: "#9b51e0",
                            marginTop: "4px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    ></ExpandMoreIcon>
                ) : (
                    <ChevronRightIcon
                        fontSize="medium"
                        style={{
                            color: "#9b51e0",
                            marginTop: "4px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    ></ChevronRightIcon>
                )}

                <Typography color="secondary" sx={{ fontSize: 22 }}>{props.header}</Typography>
            </Stack>
            <Divider></Divider>
        </Stack>
    );
};

export default Header;
