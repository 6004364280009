import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const AcsBulkBtn = (props) => {
  const theme = useTheme();
  const handleBulkNext = () => {
    if (props.activeStep === 0) {
      props.setActiveStep(5);
    }
  };

  return (
    <div style={{ textAlign: "center", margin: "20px 0px" }}>
      <Button
          variant="contained"
          size="small"
      onClick={(e) => {
      const isDisabled = !(
      props.activeStep === 0 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      !props.formListHeader.subject_code &&
      props.formListHeader.carrier &&
      props.formListHeader.dt_received_timezone
    );
    if (isDisabled) {
      e.preventDefault();
      return;
    }
    handleBulkNext();
  }}
  aria-disabled={
    !(props.activeStep === 0 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      !props.formListHeader.subject_code &&
      props.formListHeader.carrier &&
      props.formListHeader.dt_received_timezone)
  }
  role="button"
  tabIndex={0}
  aria-label={
    !(props.activeStep === 0 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      !props.formListHeader.subject_code &&
      props.formListHeader.carrier &&
      props.formListHeader.dt_received_timezone)
      ? "Bulk action - disabled until conditions are met"
      : "Perform bulk action"
  }
  sx={{
    '&[aria-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.7,
      cursor: 'not-allowed',
      // color: 'rgba(0, 0, 0, 0.56)',
      // backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.56)', 
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.12)', 
      
    },
    '&:not([aria-disabled="true"])': {
      cursor: 'pointer',
      color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff', 
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main, 
    },
    '&:focus': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '2px',
    },
    '&:focus:not(:focus-visible)': {
      outline: 'none'
    },
    '&.Mui-focusVisible': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '2px',
    }
  }}
>
  Bulk
      </Button>
    </div>
  );
};

export default AcsBulkBtn;
