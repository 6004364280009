import { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Alert,
  IconButton,
} from "../../mui";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Grid } from "@mui/material";
import ManagementService from "../../services/ManagementService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import { isEqual } from "lodash";
import AcsUniqueInvCode from "../AcsUniqueInvCode";
import dayjs from "dayjs";
import { userTimeZone, timeZonesList } from "../../components/helper/timeZone";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useTheme } from "@mui/material/styles";

const carrierTypes = [
  {
    value: "BIOCAIR",
    label: "BIOCAIR",
  },
  {
    value: "DHL",
    label: "DHL",
  },
  {
    value: "FedEx",
    label: "FedEx",
  },
  {
    value: "FedEx Ground",
    label: "FedEx Ground",
  },
  {
    value: "Hand Delivered",
    label: "Hand Delivered",
  },
  {
    value: "Marken",
    label: "Marken",
  },
  {
    value: "Priority Express",
    label: "Priority Express",
  },
  {
    value: "Quickstat",
    label: "Quickstat",
  },
  {
    value: "TNT",
    label: "TNT",
  },
  {
    value: "UPS",
    label: "UPS",
  },
  {
    value: "USA Courier",
    label: "USA Courier",
  },
  {
    value: "USPS",
    label: "USPS",
  },
  {
    value: "VIP Cargo",
    label: "VIP Cargo",
  },
  {
    value: "World Courrier",
    label: "World Courrier",
  },
];

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const tableRowData = [
  "Client",
  "Project",
  "Tracking Number",
  "Accessioning Manifest Count",
  "Carrier",
  "Date Received",
  "Time Received",
  "Timezone",
];

const AcsSendToLIMS = (props) => {
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [trackingNumberList, setTrackingNumberList] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(10);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState(false);

  const [showUnique, setShowUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const tableBarCodeData = useRef(null);
  const theme = useTheme();

  const handleCheckDuplicates = () => {
    const is_error = tableData.some(
      ({ carrier, date_received, time_received, dt_received_timezone }) =>
        !(date_received && time_received && dt_received_timezone && carrier)
    );
    if (is_error) {
      setError(true);
      // setTimeout(() => setError(false), 2000);
      return;
    }
    const req_data = tableData.map(({ client, project, tracking_number }) => {
      return { client, project, tracking_number };
    });
    setLoading(true);
    setShowUnique(true);
    ManagementService.getTableBarCodeDataLims(req_data)
      .then((resp) => {
        setLoading(false);
        tableBarCodeData.current = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };

  const handleShowUniqueClose = () => {
    setShowUnique(false);
    tableBarCodeData.current = null;
  };

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };
  const handleFieldChange = (new_value, index, cell_name) => {
    setTableData((prev) => {
      prev[index][cell_name] = new_value;
      return [...prev];
    });
  };

  const submitData = async (updatedTableBarCodeData) => {
    setDataLoading(true);
    const uniqueBarcodeData = updatedTableBarCodeData.data?.reduce(
      (accum, curr) => {
        if (curr.inventory_code !== curr.old_inventory_code)
          accum[curr.old_inventory_code] = curr.inventory_code;

        return accum;
      },
      {}
    );
    const req_body = {
      tracking_details_data: tableData,
      unique_inventory_codes: uniqueBarcodeData,
    };
    await ManagementService.saveAcsLimsTrackingNumbers(req_body)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setShowUnique(false);
        clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setDataLoading(false);
  };
  const clearForm = () => {
    props.setOpenServiceModal(false);
    props.setRefresh((prev) => !prev);
    setTableData([]);
  };

  const addToList = async () => {
    await ManagementService.getTrackingNumberDetails(
      client,
      project,
      trackingNumber
    )
      .then((resp) => {
        if (resp.data.length !== 0)
          if (
            tableData.filter(
              ({
                client,
                project,
                tracking_number,
                accessioning_mainfest_count,
              }) =>
                isEqual(
                  {
                    client,
                    project,
                    tracking_number,
                    accessioning_mainfest_count,
                  },
                  resp.data[0]
                )
            ).length === 0
          ) {
            const date_received = dayjs().format("YYYY-MM-DD");
            const time_received = dayjs().format("HH:mm");
            const dt_received_timezone = userTimeZone;
            const carrier = "";
            const newRow = {
              ...resp.data[0],
              carrier,
              date_received,
              time_received,
              dt_received_timezone,
            };
            setTableData([...tableData, newRow]);
          }
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
      });
    setTrackingNumber("");
  };

  const handleClose = () => {
    props.setOpenServiceModal(false);
    setTableData([]);
  };

  useEffect(() => {
    setTrackingNumber("");
    if (client && project) {
      ManagementService.getTrackingNumbers(client, project)
        .then((resp) => {
          setTrackingNumberList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client, project]);

  useEffect(() => {
    setProject("");
    if (client) {
      ManagementService.getClientProjects(client)
        .then((resp) => {
          setProjectList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [client]);

  useEffect(() => {
    ManagementService.getAllClients(client)
      .then((resp) => {
        setClientList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {/* Main Modal */}
      <div
        role="presentation"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleClose}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            outline: "none",
            width: "70%",
            height: "400px",
            overflowY: "auto",
          }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
          onClick={(e) => e.stopPropagation()}
        >
          <Typography
            variant="h4"
            component="div"
            role="button"
            tabIndex={0}
            id="modal-title"
            style={{
              fontFamily: "Roboto",
              fontWeight: "500",
              lineHeight: "116.7%",
              letterSpacing: "-1.5px",
            }}
            color="secondary"
          >
            Send Samples To LIMS
          </Typography>

          <Divider style={{ width: "100%" }}></Divider>

          <Stack direction="row" style={{ width: "100%" }}>
            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={client || ""}
              options={clientList}
              onInputChange={(e, newValue) => setClient(newValue)}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{ width: "30%", margin: "15px 5px 5px 5px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  name={"Client"}
                  label={"Client"}
                />
              )}
            />
            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={project || ""}
              aria-disabled={!client}
              options={client ? projectList : []}
              onInputChange={(e, newValue) => {
                if (!client) return;
                setProject(newValue);
              }}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{ width: "30%", margin: "15px 5px 5px 5px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  name={"Project"}
                  label={"Project"}
                  sx={{
                    "& .MuiFormControl-root": {
                      outline: "none !important",
                    },
                    "& .MuiInputLabel-root": {
                      color:
                        theme.palette.mode === "light"
                          ? "rgba(0, 0, 0, 0.54)"
                          : "rgba(255, 255, 255, 0.7)",
                      "&.Mui-focused": {
                        color: "primary.main",
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color:
                        theme.palette.mode === "light"
                          ? "rgba(0, 0, 0, 0.54)"
                          : "rgba(255, 255, 255, 0.7)",
                      opacity: 1,
                    },
                    // Add styles for disabled state
                    "&[aria-disabled='true']": {
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      "& .MuiInputBase-root": {
                        color: "rgba(0, 0, 0, 0.26)",
                        borderColor: "rgba(0, 0, 0, 0.12)",
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.12)",
                      },
                    },
                    // Add styles for focused state
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                      borderWidth: 2,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disabled: !client,
                  }}
                />
              )}
            />

            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={trackingNumber || ""}
              aria-disabled={!(client && project)}
              options={client && project ? trackingNumberList : []}
              onInputChange={(e, newValue) => {
                if (!(client && project)) return;
                setTrackingNumber(newValue);
              }}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{ width: "30%", margin: "15px 5px 5px 5px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={true}
                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  name={"Tracking Number"}
                  label={"Tracking Number"}
                  sx={{
                    "& .MuiFormControl-root": {
                      outline: "none !important",
                    },
                    "& .MuiInputLabel-root": {
                      color:
                        theme.palette.mode === "light"
                          ? "rgba(0, 0, 0, 0.54)"
                          : "rgba(255, 255, 255, 0.7)",
                      "&.Mui-focused": {
                        color: "primary.main",
                      },
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color:
                        theme.palette.mode === "light"
                          ? "rgba(0, 0, 0, 0.54)"
                          : "rgba(255, 255, 255, 0.7)",
                      opacity: 1,
                    },
                    "&[aria-disabled='true']": {
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      "& .MuiInputBase-root": {
                        color: "rgba(0, 0, 0, 0.26)",
                        borderColor: "rgba(0, 0, 0, 0.12)",
                        pointerEvents: "none",
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.12)",
                      },
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                      borderWidth: 2,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disabled: !(client && project),
                  }}
                />
              )}
            />
            <Button
              aria-disabled={!trackingNumber}
              type="submit"
              variant="outlined"
              role="button"
              tabIndex={0}
              onClick={(e) => {
                if (!trackingNumber) {
                  e.preventDefault();
                  return;
                }
                addToList();
              }}
              aria-label={
                !trackingNumber
                  ? "Add to list - Enter tracking number first"
                  : "Add tracking number to list"
              }
              sx={{
                width: "10%",
                margin: "15px 5px 5px 5px",
                '&[aria-disabled="true"]': {
                  pointerEvents: "none",

                  cursor: "not-allowed",
                  color:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.3)"
                      : "rgba(0, 0, 0, 0.26)",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.12)"
                      : "rgba(0, 0, 0, 0.12)",
                },
                '&:not([aria-disabled="true"])': {
                  cursor: "pointer",
                },
                "&:focus": {
                  outline: "2px solid",
                  outlineColor: "primary.main",
                  outlineOffset: "2px",
                },
                "&:focus:not(:focus-visible)": {
                  outline: "none",
                },
                "&.Mui-focusVisible": {
                  outline: "2px solid",
                  outlineColor: "primary.main",
                  outlineOffset: "2px",
                },
              }}
            >
              Add
            </Button>
          </Stack>

          <Box sx={{ width: "100%" }}>
            <Grid
              // container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {tableData && (
                <section aria-label="Sample data table">
                  <TableContainer style={{ marginTop: "30px" }}>
                    <Table
                      stickyHeader={true}
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      size={"small"}
                    >
                      <TableHead sx={{ minWidth: 650 }}>
                        <TableRow sx={{ minWidth: 650 }}>
                          {tableRowData.map((row, index) => (
                            <TableCell
                              align="left"
                              key={index}
                              style={{ fontWeight: "bold" }}
                            >
                              {row}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ border: "none" }}>
                        {tableData !== 0 &&
                          tableData
                            .slice(
                              pageLarge * rowsPerPageLarge,
                              pageLarge * rowsPerPageLarge + rowsPerPageLarge
                            )
                            .map((row, index) => (
                              <TableRow
                                hover={true}
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  {row.client || "None / Null"}
                                </TableCell>
                                <TableCell align="left">
                                  {row.project || "None / Null"}
                                </TableCell>
                                <TableCell align="left">
                                  {row.tracking_number || "None / Null"}
                                </TableCell>

                                <TableCell align="left">
                                  {row.accessioning_mainfest_count}
                                </TableCell>
                                <TableCell align="left">
                                  <FormControl
                                    variant="standard"
                                    sx={{ minWidth: "150px" }}
                                    size="small"
                                    error={!row.carrier}
                                  >
                                    <InputLabel
                                      style={visuallyHidden}
                                      id={`carrier-label-${index}`}
                                    >
                                      Carrier
                                    </InputLabel>
                                    <Select
                                      labelId={`carrier-label-${index}`}
                                      id="carrier"
                                      value={row.carrier || ""}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          e.target.value,
                                          index,
                                          "carrier"
                                        )
                                      }
                                      aria-label="Carrier"
                                      sx={{
                                        marginTop: "0 !important",
                                      }}
                                    >
                                      {carrierTypes.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value || ""}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    id="r_date"
                                    label={
                                      <span style={{ display: "none" }}>
                                        Date Received
                                      </span>
                                    }
                                    type="date"
                                    error={!row.date_received}
                                    variant="standard"
                                    value={row.date_received}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e.target.value,
                                        index,
                                        "date_received"
                                      )
                                    }
                                    size="small"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        marginTop: "0px",
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <TextField
                                    id="r_time"
                                    label={
                                      <span style={{ display: "none" }}>
                                        Time Received
                                      </span>
                                    }
                                    type="time"
                                    variant="standard"
                                    value={row.time_received}
                                    error={!row.time_received}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e.target.value,
                                        index,
                                        "time_received"
                                      )
                                    }
                                    size="small"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        marginTop: "0px",
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <Autocomplete
                                    id="tags-tz"
                                    freeSolo
                                    value={row.dt_received_timezone}
                                    options={timeZonesList}
                                    onChange={(e, new_value) =>
                                      handleFieldChange(
                                        new_value,
                                        index,
                                        "dt_received_timezone"
                                      )
                                    }
                                    getOptionLabel={(option) =>
                                      typeof option === "string" ||
                                      option instanceof String
                                        ? option
                                        : ""
                                    }
                                    style={{
                                      minWidth: "140px",
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        error={
                                          !row.dt_received_timezone?.trim()
                                        }
                                        required={true}
                                        id="user-tz"
                                        size="small"
                                        type="text"
                                        name={"user_timezone"}
                                        label={
                                          <span style={visuallyHidden}>
                                            Time Received
                                          </span>
                                        }
                                        sx={{
                                          "& .MuiFormLabel-asterisk": {
                                            display: "none",
                                          },
                                          "& .MuiInputBase-root": {
                                            marginTop: "0px",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={tableData?.length}
                      rowsPerPage={rowsPerPageLarge}
                      page={pageLarge}
                      onPageChange={handleChangePageLarge}
                      onRowsPerPageChange={handleChangeRowsPerPageLarge}
                      labelRowsPerPage={
                        <span
                          tabIndex={0}
                          role="status"
                          aria-label="Rows per page selector"
                          style={{
                            padding: "4px 8px",
                            borderRadius: "4px",
                            cursor: "default",
                          }}
                        >
                          Rows per page:
                        </span>
                      }
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                          role: "combobox",
                        },
                        SelectDisplayProps: {
                          tabIndex: 0,
                        },
                        sx: {
                          "& .MuiSelect-select": {
                            paddingY: "8px",
                            minHeight: "auto",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.primary.main,
                            borderWidth: 2,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.primary.main,
                          },
                          "&:focus-within": {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: "2px",
                            borderRadius: "4px",
                          },
                          "&.Mui-focused": {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: "2px",
                            borderRadius: "4px",
                          },
                        },
                      }}
                      ActionsComponent={(props) => (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => {
                              if (props.page > 0) {
                                props.onPageChange(null, props.page - 1);
                              }
                            }}
                            aria-label="Previous page"
                            tabIndex={0}
                            aria-disabled={props.page === 0}
                            onKeyDown={(e) => {
                              if (
                                (e.key === "Enter" || e.key === " ") &&
                                props.page > 0
                              ) {
                                e.preventDefault();
                                props.onPageChange(null, props.page - 1);
                              }
                            }}
                            sx={{
                              "&:focus": {
                                outline: "none",
                                boxShadow: (theme) =>
                                  `0 0 0 2px ${theme.palette.primary.main}`,
                              },
                              '&[aria-disabled="true"]': {
                                color:
                                  theme.palette.mode === "dark"
                                    ? "rgba(255, 255, 255, 0.3)"
                                    : "rgba(0, 0, 0, 0.26)",
                                cursor: "default",
                              },
                              '&[aria-disabled="false"]': {
                                color: "inherit",
                              },
                            }}
                          >
                            <KeyboardArrowLeft />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (
                                props.page <
                                Math.ceil(props.count / props.rowsPerPage) - 1
                              ) {
                                props.onPageChange(null, props.page + 1);
                              }
                            }}
                            aria-label="Next page"
                            tabIndex={0}
                            aria-disabled={
                              props.page >=
                              Math.ceil(props.count / props.rowsPerPage) - 1
                            }
                            onKeyDown={(e) => {
                              if (
                                (e.key === "Enter" || e.key === " ") &&
                                props.page <
                                  Math.ceil(props.count / props.rowsPerPage) - 1
                              ) {
                                e.preventDefault();
                                props.onPageChange(null, props.page + 1);
                              }
                            }}
                            sx={{
                              "&:focus": {
                                outline: "none",
                                boxShadow: (theme) =>
                                  `0 0 0 2px ${theme.palette.primary.main}`,
                              },
                              '&[aria-disabled="true"]': {
                                color:
                                  theme.palette.mode === "dark"
                                    ? "rgba(255, 255, 255, 0.3)"
                                    : "rgba(0, 0, 0, 0.26)",
                              },
                              '&[aria-disabled="false"]': {
                                color: "inherit",
                              },
                            }}
                          >
                            <KeyboardArrowRight />
                          </IconButton>
                        </Box>
                      )}
                    />
                  </TableContainer>
                </section>
              )}
            </Grid>
          </Box>

          {error && (
            <section aria-label="Error messages">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="5px"
              >
                <Alert
                  style={{ top: "30%" }}
                  onClose={() => setError(false)}
                  severity="warning"
                >
                  All Fields are mandatory. Please enter correct details.
                </Alert>
              </Box>
            </section>
          )}

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button
              aria-disabled={tableData.length === 0 || dataLoading}
              type="submit"
              variant="contained"
              role="button"
              tabIndex={0}
              onClick={(e) => {
                if (tableData.length === 0 || dataLoading) {
                  e.preventDefault();
                  return;
                }
                handleCheckDuplicates();
              }}
              sx={{
                width: "100%",
                margin: "15px 5px 5px 5px",
                '&[aria-disabled="true"]': {
                  pointerEvents: "none",
                  cursor: "not-allowed",
                  color:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.3)"
                      : "rgba(0, 0, 0, 0.26)",
                  borderColor: "rgba(0, 0, 0, 0.12)",
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.12)"
                      : "rgba(0, 0, 0, 0.12)",
                },
                '&:not([aria-disabled="true"])': {
                  cursor: "pointer",
                },
                "&:focus": {
                  outline: "2px solid",
                  outlineColor: "primary.main",
                  outlineOffset: "2px",
                },
                "&:focus:not(:focus-visible)": {
                  outline: "none",
                },
                "&.Mui-focusVisible": {
                  outline: "2px solid",
                  outlineColor: "primary.main",
                  outlineOffset: "2px",
                },
              }}
            >
              Send To LIMS
            </Button>
          </Box>
        </Box>
      </div>

      {/* Unique Modal  */}
      {showUnique && (
        <div
          role="presentation"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleShowUniqueClose}
        >
          <Box sx={{ flexGrow: 1, overflow: "auto", mt: 2 }}>
            <AcsUniqueInvCode
              loading={loading}
              tableBarCodeData={tableBarCodeData.current}
              processing={submitLoading}
              hamdleParentSubmission={submitData}
              service_name="management_console"
            />
          </Box>
        </div>
      )}
    </>
  );
};

export default AcsSendToLIMS;
