import { styled } from "@mui/material/styles";
import { useState } from "react";
import { FileDrop } from "react-file-drop";
import { useDispatch } from "react-redux";
import { Box, Button, CloudUploadIcon, Divider, Typography } from "../../mui";
import { snackbarActions } from "../../Store/snackbar";
import { Grid } from "@mui/material";

const fileTypeMapping = {
  csv: "text/csv",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xml: "text/xml",
  xls: "application/vnd.ms-excel",
};

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CustomizedFileDrop = styled(FileDrop)(({ theme }) => ({
  flex: 1,
  "& .file-drop-target": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AcsEditFileBulkUpload = ({
  multi,
  files,
  setFiles,
  accept,
  disabled,
  setOpen,
  downloadTemplate,
}) => {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);

  const validTypes = accept.map((key) => {
    return fileTypeMapping[key];
  });

  const handleFileDrop = (files, event) => {
    event.preventDefault();
    const filesValidation = files[0];

    if (!validTypes.includes(filesValidation.type)) {
      console.log(filesValidation.type);
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Invalid File Format, must be of type: ${accept.join(
            ", "
          )}`,
        })
      );
      return;
    }

    setFiles(files[0]);
    dispatch(
      snackbarActions.showNotification({
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "File uploaded successfully.",
      })
    );

    setOpen(false);
  };

  const handleFileUpload = (event) => {
    const filesValidation = event.target.files[0];
    if (!validTypes.includes(filesValidation.type)) {
      console.log("Invalid Type");
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Invalid File Format, must be of type: ${accept.join(
            ","
          )}`,
        })
      );
      return;
    }

    setFiles(event.target.files[0]);
    dispatch(
      snackbarActions.showNotification({
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "File uploaded successfully.",
      })
    );
    // setErrorData([]);
    event.target.value = null;
    setOpen(false);
  };

  return (
    <main>
      <Grid container spacing={2} sx={style} align="center" justify="center">
        <Grid item xs={12}>
          <Typography>
            Upload a file with one columns as: <b>Inventory Code</b>
            <br /> <br />
            <Typography>
              Quickly{" "}
              <Button
                // disabled={clientState && projectState ? false : true}
                onClick={downloadTemplate}
                style={{
                  textTransform: "capitalize",
                  padding: "0",
                  margin: "-1px 0 0 0",
                  fontSize: "0.85rem",
                }}
              >
                Download
              </Button>{" "}
              the template file
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} align="center" justify="center">
          <Box
            sx={{
              display: "flex",
              bgcolor: hover ? "rgb(200,216,280,0.6)" : "rgb(216,229,242,0.5)",
              borderRadius: "5px",
            }}
          >
            <CustomizedFileDrop
              onFrameDragEnter={(event) => setHover(true)}
              onFrameDragLeave={(event) => setHover(false)}
              onFrameDrop={(event) => setHover(false)}
              onDragOver={(event) => { }}
              onDragLeave={(event) => { }}
              onDrop={(files, event) => handleFileDrop(files, event)}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloudUploadIcon
                  sx={{
                    mt: 2,
                    fontSize: 60,
                    color: "secondary.light",
                  }}
                />
                <Typography sx={{ mb: 1, fontSize: 15 }} tabIndex={0}>
                  Just Drag and Drop
                </Typography>
                <Divider flexItem>
                  <Typography variant="h6" tabIndex={0}>or</Typography>
                </Divider>
                <Button
                  sx={{
                    mb: 2.5,
                    mt: 1,
                  }}
                  onClick={() => document.getElementById('file-upload-input').click()}
                  variant="contained"
                  color="blue"
                  disabled={disabled}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      document.getElementById('file-upload-input').click();
                    }
                  }}
                >
                  Choose a File
                  <input
                    id="file-upload-input"
                    type="file"
                    name="file"
                    accept=".xlsx"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
            </CustomizedFileDrop>
          </Box>
        </Grid>
      </Grid>
    </main>
  );
};

export default AcsEditFileBulkUpload;
