import { useEffect, useState, memo } from "react";
import { Box, Paper, Typography, Stack, IconButton } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import AcsService from "../../services/AcsService";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const containerColumns = [
  {
    field: "material",
    headerName: "Material",
    flex: 1,
    editable: false,
  },
  {
    field: "supplier",
    headerName: "Supplier",
    flex: 1,
    editable: false,
  },
  {
    field: "catalog",
    headerName: "Catalog",
    flex: 1,
    editable: false,
    //  headerAlign: "center",
  },
];
const sourceColumns = [
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    editable: false,
  },
  {
    field: "source_name",
    headerName: "Source Name",
    flex: 1,
    editable: false,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const ManifestHints = () => {
  const [lvcontainerRows, setLvcontainerRows] = useState([]);
  const [starLIMScontainerRows, setStarLIMScontainerRows] = useState([]);
  const [sourceRows, setSourceRows] = useState([]);

  const theme = useTheme();

  useEffect(() => {
    AcsService.getAllStarLIMSContainerTypes()
      .then((resp) => {
        setStarLIMScontainerRows(resp.data);
      })
      .catch((err) => {
        console.log("err");
      });
    AcsService.getAllLvContainerTypes()
      .then((resp) => {
        setLvcontainerRows(resp.data);
      })
      .catch((err) => {
        console.log("err");
      });
    AcsService.getAllSources()
      .then((resp) => {
        setSourceRows(resp.data);
      })
      .catch((err) => {
        console.log("err");
      });
  }, []);

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
    const rowCount = apiRef.current.getRowsCount();

    const theme = useTheme();
    // Calculate current range of visible rows
    const startRow = page * pageSize + 1;
    const endRow = Math.min((page + 1) * pageSize, rowCount);

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "8px 16px",
          width: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <label
            id="rows-per-page-label"
            htmlFor="rows-per-page-select"
            style={{
              display: "flex",
              alignItems: "center",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "0.8rem",
              fontWeight: "400",
              lineHeight: "1.43",
              margin: "0",
              padding: "0",
            }}
          >
            <span
              tabIndex={0}
              role="status"
              aria-label="Rows per page selector"
              style={{
                padding: "4px 8px",
                borderRadius: "4px",
                cursor: "default",
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.buttonDefault.main
                    : theme.palette.black.main,
              }}
              sx={{
                "&:focus": {
                  outline: "2px solid #9b51e0",
                  outlineOffset: "2px",
                  backgroundColor: "rgba(155, 81, 224, 0.08)",
                },
                "&:focus-visible": {
                  outline: "2px solid #9b51e0",
                  outlineOffset: "2px",
                  backgroundColor: "rgba(155, 81, 224, 0.08)",
                },
              }}
            >
              Rows per page:
            </span>
          </label>
          <Select
            id="rows-per-page-select"
            value={pageSize}
            onChange={(e) => apiRef.current.setPageSize(Number(e.target.value))}
            labelId="rows-per-page-label"
            aria-labelledby="rows-per-page-label"
            inputProps={{
              "aria-label": "Rows per page",
            }}
            sx={{
              marginLeft: "8px",
              minWidth: 65,
              "& .MuiSelect-select": {
                padding: "4px 8px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "2px",
              },
            }}
          >
            {[25, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography
          variant="body2"
          tabIndex={0}
          sx={{ mx: 2, ml: 2 }}
          role="status"
          aria-live="polite"
        >
          {startRow}-{endRow} of {rowCount}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              if (page > 0) {
                apiRef.current.setPage(page - 1);
              }
            }}
            // Remove the disabled prop to keep it focusable
            // disabled={page === 0}
            size="small"
            aria-label="Previous page"
            // Always keep it in the tab order
            tabIndex={0}
            // Use aria-disabled for accessibility
            aria-disabled={page === 0}
            onKeyDown={(e) => {
              // Allow keyboard activation with Enter or Space
              if ((e.key === "Enter" || e.key === " ") && page > 0) {
                e.preventDefault();
                apiRef.current.setPage(page - 1);
              }
            }}
            sx={{
              "&:focus": {
                outline: "none",
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
              },
              '&[aria-disabled="true"]': {
                color:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.3)"
                    : "rgba(0, 0, 0, 0.26)",
                cursor: "default",
              },
              '&[aria-disabled="false"]': {
                color: "inherit",
              },
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>

          <IconButton
            onClick={() => {
              if (page < pageCount - 1) {
                apiRef.current.setPage(page + 1);
              }
            }}
            aria-disabled={page >= pageCount - 1}
            size="small"
            aria-label="Next page"
            tabIndex={0}
            sx={{
              "&:focus": {
                outline: "none",
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
              },
              '&[aria-disabled="true"]': {
                color:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.3)"
                    : "rgba(0, 0, 0, 0.26)",
              },
              '&[aria-disabled="false"]': {
                color: "inherit",
              },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      alignItems="center"
      flexWrap="wrap"
      paddingBottom="10px"
      component={Paper}
      variant={"outlined"}
    >
      <Stack
        sx={{
          // height: "450px",
          width: "100%",
          maxWidth: "530px",
          marginTop: "20px",
        }}
      >
        <Typography
          color="secondary"
          sx={{ mb: 1, fontSize: 18, fontWeight: "700" }}
        >
          StarLIMS: Valid Containers Type
        </Typography>
        <DataGrid
          rows={starLIMScontainerRows}
          columns={containerColumns}
          slots={{
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            cell: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
            columnHeader: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
            "& .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          // height: "450px",
          width: "100%",
          maxWidth: "530px",
          marginTop: "20px",
        }}
      >
        <Typography
          color="secondary"
          sx={{ mb: 1, fontSize: 18, fontWeight: "700" }}
        >
          LabVantage: Valid Containers Type
        </Typography>
        <DataGrid
          rows={lvcontainerRows}
          columns={containerColumns}
          slots={{
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            cell: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
            columnHeader: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
            "& .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          // height: "450px",
          width: "90%",
          maxWidth: "370px",
          marginTop: "20px",
        }}
      >
        <Typography
          color="secondary"
          sx={{ mb: 1, fontSize: 18, fontWeight: "700" }}
        >
          Valid Sources
        </Typography>
        <DataGrid
          rows={sourceRows}
          columns={sourceColumns}
          slots={{
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            cell: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
            columnHeader: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
            "& .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default memo(ManifestHints);
