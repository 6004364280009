import { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import ManagementService from "../../services/ManagementService";
import UIWrapper from "../../components/UIWrapper";
import AcsSendToLIMS from "./AcsSendToLims";

import { CSVLink } from "react-csv";
import { Typography, Button, IconButton, Box } from "../../mui";
import BiService from "../../services/BiService";
import { snackbarActions } from "../../Store/snackbar";
import { useDispatch } from "react-redux";
import DeleteManifest from "./DeleteManifest";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
  const rowCount = apiRef.current.getRowsCount();

  const theme = useTheme();
  // Calculate current range of visible rows
  const startRow = page * pageSize + 1;
  const endRow = Math.min((page + 1) * pageSize, rowCount);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        padding: "8px 16px",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <label
          id="rows-per-page-label"
          htmlFor="rows-per-page-select"
          style={{
            display: "flex",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "0.8rem",
            fontWeight: "400",
            lineHeight: "1.43",
            margin: "0",
            padding: "0",
          }}
        >
          <span
            tabIndex={0}
            role="status"
            aria-label="Rows per page selector"
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              cursor: "default",
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.buttonDefault.main
                  : theme.palette.black.main,
            }}
            sx={{
              "&:focus": {
                outline: "2px solid #9b51e0",
                outlineOffset: "2px",
                backgroundColor: "rgba(155, 81, 224, 0.08)",
              },
              "&:focus-visible": {
                outline: "2px solid #9b51e0",
                outlineOffset: "2px",
                backgroundColor: "rgba(155, 81, 224, 0.08)",
              },
            }}
          >
            Rows per page:
          </span>
        </label>
        <Select
          id="rows-per-page-select"
          value={pageSize}
          onChange={(e) => apiRef.current.setPageSize(Number(e.target.value))}
          labelId="rows-per-page-label"
          aria-labelledby="rows-per-page-label"
          inputProps={{
            "aria-label": "Rows per page",
          }}
          sx={{
            marginLeft: "8px",
            minWidth: 65,
            "& .MuiSelect-select": {
              padding: "4px 8px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "2px",
            },
          }}
        >
          {[25, 50, 100].map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Typography
        variant="body2"
        tabIndex={0}
        sx={{ mx: 2, ml: 2 }}
        role="status"
        aria-live="polite"
      >
        {startRow}-{endRow} of {rowCount}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            if (page > 0) {
              apiRef.current.setPage(page - 1);
            }
          }}
          // Remove the disabled prop to keep it focusable
          // disabled={page === 0}
          size="small"
          aria-label="Previous page"
          // Always keep it in the tab order
          tabIndex={0}
          // Use aria-disabled for accessibility
          aria-disabled={page === 0}
          onKeyDown={(e) => {
            // Allow keyboard activation with Enter or Space
            if ((e.key === "Enter" || e.key === " ") && page > 0) {
              e.preventDefault();
              apiRef.current.setPage(page - 1);
            }
          }}
          sx={{
            "&:focus": {
              outline: "none",
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
            },
            '&[aria-disabled="true"]': {
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.26)",
              cursor: "default",
            },
            '&[aria-disabled="false"]': {
              color: "inherit",
            },
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          onClick={() => {
            if (page < pageCount - 1) {
              apiRef.current.setPage(page + 1);
            }
          }}
          aria-disabled={page >= pageCount - 1}
          size="small"
          aria-label="Next page"
          tabIndex={0}
          sx={{
            "&:focus": {
              outline: "none",
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
            },
            '&[aria-disabled="true"]': {
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.26)",
            },
            '&[aria-disabled="false"]': {
              color: "inherit",
            },
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const PendingAccessionColumns = [
  {
    field: "client",
    headerName: "Client",
    minWidth: 150,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "project",
    headerName: "Project",
    minWidth: 150,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "inventory_code",
    headerName: "Inventory Code",
    minWidth: 200,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "source",
    headerName: "Source",
    minWidth: 150,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "container_type",
    headerName: "Container Type",
    minWidth: 150,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "tracking_number",
    headerName: "Tracking Number",
    minWidth: 200,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
];

const PendingAccession = () => {
  const [tableData, setTableData] = useState([]);
  const [openAccessioningModal, setOpenAccessioningModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [inventoryCodeData, setInventoryCodeData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const apiRef = useGridApiRef();
  const csvLink = useRef();
  const dispatch = useDispatch();

  const handleExport = async (event) => {
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);
    date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
    date = date.replace(/\s/g, "");
    date = date.slice(0, -2);
    setFileName("pending_sample_export_" + date + ".csv");
    let keyArray = tableData.map(function (item) {
      return item["inventory_code"];
    });
    await BiService.exportSearch(keyArray)
      .then((resp) => {
        setInventoryCodeData(resp.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Export Failed.",
          })
        );
      });
  };

  const handleOpenModal = () => {
    setOpenAccessioningModal(true);
  };

  useEffect(() => {
    setDataLoading(true);

    const getSample = async () => {
      await ManagementService.getPendingSamples()
        .then((resp) =>
          setTableData(
            resp.data.map((row, id) => {
              return { id, ...row };
            })
          )
        )
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed to get Samples Pending Accessioning.",
            })
          );
        });
      setDataLoading(false);
    };
    getSample();

    return;
  }, [refresh]);

  useEffect(() => {
    if (inventoryCodeData.length !== 0) {
      csvLink.current.link.click();
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Export Succesful.",
        })
      );
    }
  }, [inventoryCodeData]);

  return (
    <UIWrapper title="Samples Pending Accessioning" paper>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Total Number of Samples pending Accessioning: {tableData.length}
        </Typography>

        <DataGrid
          rows={tableData}
          columns={PendingAccessionColumns}
          slots={{
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            cell: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
            columnHeader: {
              tabIndex: 0,
              onKeyDown: (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
            "& .MuiDataGrid-columnHeader": {
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
          }}
        />
      </Box>

      {tableData && tableData.length !== 0 && (
        <>
          <Button
            style={{ marginTop: "15px" }}
            type="submit"
            variant="contained"
            onClick={handleExport}
          >
            Export
          </Button>
          <Button
            style={{ marginTop: "15px", marginLeft: "20px" }}
            type="submit"
            variant="contained"
            onClick={handleOpenModal}
          >
            Accelerated Accessioning: Send To Lims
          </Button>
          <Button
            style={{ marginTop: "15px", marginLeft: "20px" }}
            type="submit"
            variant="contained"
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            Delete Manifest
          </Button>
        </>
      )}

      {inventoryCodeData.length ? (
        <CSVLink
          data={inventoryCodeData}
          filename={fileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      ) : null}
      {openAccessioningModal ? (
        <AcsSendToLIMS
          openServiceModal={openAccessioningModal}
          setOpenServiceModal={setOpenAccessioningModal}
          setRefresh={setRefresh}
        />
      ) : null}
      {openDeleteModal ? (
        <DeleteManifest
          openServiceModal={openDeleteModal}
          setOpenServiceModal={setOpenDeleteModal}
          setRefresh={setRefresh}
        />
      ) : null}
    </UIWrapper>
  );
};

export default PendingAccession;
