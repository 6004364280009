import { useState, useRef, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { snackbarActions } from "../.././Store/snackbar";
import AcsService from "../../services/AcsService";
import AcsUniqueInvCode from "../AcsUniqueInvCode";
import { visuallyHidden } from "@mui/utils";

// const steps = ['Client', 'Project', 'Tracking Number', 'Subject Code', 'Visit ID'];
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

export default function AcsPreview(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [tableDetail, setTableDetail] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [subjectExpanded, setSubjectExpanded] = useState(false);
  const [sampleExpanded, setSampleExpanded] = useState(false);
  const [invExpanded, setInvExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitData, setSubmitData] = useState({});

  const [showUnique, setShowUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const tableBarCodeData = useRef(null);
  const [opens, setOpens] = useState(false);

  const handleOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleCheckDuplicates = () => {
    const allInvCodes =
      submitData.samples &&
      submitData.samples.length !== 0 &&
      submitData.samples.map((ele) => ele.inventory_code);
    const req_data = {
      client: submitData.client,
      project: submitData.project,
      inventory_codes: allInvCodes || [],
    };
    setLoading(true);
    setShowUnique(true);
    AcsService.getTableBarCodeDataAccession(req_data)
      .then((resp) => {
        setLoading(false);
        tableBarCodeData.current = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Failed Request",
          })
        );
      });
  };

  const handleShowUniqueClose = () => {
    setShowUnique(false);
    tableBarCodeData.current = null;
  };
  const handleApprove = async (updatedTableBarCodeData) => {
    setSubmitLoading(true);
    const uniqueBarcodeData = updatedTableBarCodeData.data?.reduce(
      (accum, curr) => {
        if (curr.inventory_code !== curr.old_inventory_code)
          accum[curr.old_inventory_code] = curr.inventory_code;

        return accum;
      },
      {}
    );

    let final = JSON.parse(JSON.stringify(submitData));
    let arrUrgent = [];
    final.samples.forEach((ele) => {
      if (ele["urgent"]) arrUrgent.push(ele.inventory_code);
    });
    final["urgent"] = arrUrgent;
    final["unique_inventory_codes"] = uniqueBarcodeData;

    await AcsService.submitForm(final)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Ingested Samples",
          })
        );
        setShowUnique(false);
        props.clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
      });
    setSubmitLoading(false);
  };

  const handleCheckAll = () => {
    let newData = JSON.parse(JSON.stringify(submitData));
    newData.samples.forEach((ele, index) => {
      newData.samples[index]["urgent"] = true;
    });
    setSubmitData(newData);
  };

  const handleUncheckAll = () => {
    let newData = JSON.parse(JSON.stringify(submitData));
    newData.samples.forEach((ele, index) => {
      newData.samples[index]["urgent"] = false;
    });
    setSubmitData(newData);
  };
  const handleDetailData = (detail) => {
    setTableDetail(detail);
    setOpenDetail(true);
  };
  const handleSubmission = () => {
    let finalData = {
      client: props.client,
      project: props.project,
      //   subject_code: props.subjectCode,
      site: props.formListHeader.site,
      tracking_number: props.trackingNumber,
      carrier: props.formListHeader.carrier,
      date_received: props.formListHeader.date_received,
      time_received: props.formListHeader.time_received,
      dt_received_timezone: props.formListHeader.dt_received_timezone,
      comments: props.comments,

      // "static": {
      //     // "site": props.formList.site,
      //     // "gender": props.formList.gender,
      //     // "subject_id": props.formList.USUBJID,
      //     // "alternate_id": props.formList.alternate_id,
      //     // "date_of_birth": props.formList.date_of_birth,
      //     // "draw_date": props.formList.draw_date,
      //     // "draw_time": props.formList.draw_time
      // },
      subjects: [],
      samples: [],
    };

    // Object.keys(props.formList).forEach(ele => {
    //     if (props.formList[ele] && props.formList[ele] !== '') {
    //         finalData.static[ele] = props.formList[ele]
    //     }
    // })

    // Object.keys(props.dynamicValuesSubject).forEach((ele) => {
    //   if (
    //     props.dynamicValuesSubject[ele] &&
    //     props.dynamicValuesSubject[ele] !== ""
    //   ) {
    //     finalData.subject[ele] = props.dynamicValuesSubject[ele];
    //   }
    // });

    props.formValuesSubject.forEach((ele, index) => {
      let temp = {};

      Object.keys(ele).forEach((option) => {
        if (ele[option] && ele[option] !== "") {
          temp[option] = ele[option];
        }
      });
      let pushData = JSON.parse(JSON.stringify(temp));
      finalData.subjects.push(pushData);
    });

    props.formValues.forEach((ele, index) => {
      let temp = {};
      temp["urgent"] = false;

      Object.keys(ele.data).forEach((option) => {
        if (ele.data[option] !== "") {
          temp[option] = ele.data[option];
        }
      });
      temp["source"] = ele.source;
      temp["container_type"] = ele["container_type"];
      ele.inventory_code.forEach((option, pos) => {
        let pushData = JSON.parse(JSON.stringify(temp));
        pushData["inventory_code"] = option;
        finalData.samples.push(pushData);
      });
    });
    setSubmitData(finalData);

    setOpen(true);
  };
  const handleCheckChange = (index) => {
    let newData = JSON.parse(JSON.stringify(submitData));
    newData.samples[index]["urgent"] = !newData.samples[index]["urgent"];
    setSubmitData(newData);
  };
  return (
    <Paper
      style={{ padding: "20px", margin: "35px 0px", background: "#eaeaf0" }}
      variant="outlined"
    >

      {
        showUnique &&
        <div
          role="presentation"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleShowUniqueClose}
        >
          <Stack onClick={(e) => e.stopPropagation()}>
            <AcsUniqueInvCode
              loading={loading}
              tableBarCodeData={tableBarCodeData.current}
              processing={submitLoading}
              hamdleParentSubmission={handleApprove}
              service_name="accession"
            />
          </Stack>
        </div>
      }
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontSize: "1.25rem",
          color: (theme) =>
            theme.palette.mode === "dark"
              ? "#000000" // Pure white for dark mode - ensures 4.5:1 contrast ratio
              : "#000000", // Near black for light mode - ensures 4.5:1 contrast ratio
          marginBottom: "10px",
          height: "100%",
        }}
      >
        Preview Data Inputs:
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" tabIndex={0}>
                Inventory Codes
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Client
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Project
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Subject Code
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Site
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Carrier Type
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Tracking Number
              </TableCell>
              <TableCell align="center" tabIndex={0}>
                Date Received
              </TableCell>

              <TableCell align="center" tabIndex={0}>
                More..
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.formValues.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${row.inventory_code}`}
                >
                  {row.inventory_code[0] || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${row.client}`}
                >
                  {props.client || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${row.project}`}
                >
                  {props.project || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${props.formValuesSubject.map((option, i) => {
                    if (option.inventory_codes.length !== 0) {
                      return option.inventory_codes.map((ele) => {
                        if (ele === row.inventory_code[0]) {
                          return option.subject_code || "null";
                        }
                      });
                    }
                  })}`}
                >
                  {props.formValuesSubject.map((option, i) => {
                    if (option.inventory_codes.length !== 0) {
                      return option.inventory_codes.map((ele) => {
                        if (ele === row.inventory_code[0]) {
                          return option.subject_code || "null";
                        }
                      });
                    }
                  })}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${props.formListHeader.site}`}
                >
                  {props.formListHeader.site || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${props.formListHeader.carrier}`}
                >
                  {props.formListHeader.carrier || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${props.trackingNumber}`}
                >
                  {props.trackingNumber || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  scope="row"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`${props.formListHeader.date_received}`}
                >
                  {props.formListHeader.date_received || "null"}
                </TableCell>

                <TableCell
                  align="center"
                  tabIndex={0}
                  role="gridcell"
                  onClick={(e) => handleDetailData(row)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                      handleDetailData(row);
                    }
                  }}
                  aria-label="View more details"
                >
                  <Tooltip title="More Detail" style={{ cursor: "pointer" }}>
                    <ReadMoreIcon></ReadMoreIcon>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TextField
        multiline
        id="comments-input"
        label="Comments"
        type="text"
        aria-label="Comments field"
        aria-description="Enter comments about the samples (maximum 200 characters)"
        sx={{
          margin: "15px 0px",
          width: "100%",
          "& .MuiInputBase-input": {
            color: "#000000",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#000000",
            },
          },
        }}
        size="small"
        value={props.comments || ""}
        onChange={(event) => props.setComments(event.target.value)}
        minRows={2}
        inputProps={{
          maxLength: 200,
          "aria-required": false,
        }}
        InputLabelProps={{
          htmlFor: "comments-input",
          sx: {
            color: "#000000",
          },
        }}
      />

      <Stack
        direction="row"
        style={{ width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          style={{ width: "10%" }}
          onClick={handleSubmission}
        >
          submit
        </Button>
      </Stack>

      <div
        role="presentation"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1300,
          display: openDetail ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setOpenDetail(false)}
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby="detail-modal-title"
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "24px",
            width: "40vw",
            maxWidth: "40vw",
            maxHeight: "50vh",
            overflow: "auto",
            position: "relative",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <h2 id="detail-modal-title" style={visuallyHidden}>
            Detail Modal
          </h2>

          <div>
            {/* Subject Section */}
            <div role="region" aria-labelledby="subject-section">
              <button
                id="subject-section"
                aria-expanded={subjectExpanded}
                onClick={() => setSubjectExpanded(!subjectExpanded)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    setSubjectExpanded(!subjectExpanded);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {subjectExpanded ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    style={{ color: "#9b51e0" }}
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    style={{ color: "#9b51e0" }}
                  />
                )}
                <Typography
                  sx={{ fontSize: 22, marginLeft: "8px", color: "#000000" }}
                >
                  Subject
                </Typography>
              </button>

              {subjectExpanded && (
                <div style={{ padding: "8px 16px" }}>
                  {Object.keys(props.dynamicValuesSubject).map((row, index) => (
                    <Stepper nonLinear style={{ padding: "2px" }} key={index}>
                      <Step completed={false}>
                        <Typography sx={{ fontSize: 14, color: "#000000" }}>
                          {row}
                        </Typography>
                      </Step>
                      <Step completed={row.status === "COMPLETED"}>
                        <Typography sx={{ fontSize: 14, color: "#000000" }}>
                          {props.formValuesSubject.map((item) =>
                            item.inventory_codes.map((inv) =>
                              inv === tableDetail.inventory_code[0]
                                ? item[row] || "null"
                                : null
                            )
                          )}
                        </Typography>
                      </Step>
                    </Stepper>
                  ))}
                </div>
              )}
            </div>

            {/* Inventory Codes Section */}
            <div role="region" aria-labelledby="inventory-section">
              <button
                id="inventory-section"
                aria-expanded={invExpanded}
                onClick={() => setInvExpanded(!invExpanded)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    setInvExpanded(!invExpanded);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {invExpanded ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    style={{ color: "#9b51e0" }}
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    style={{ color: "#9b51e0" }}
                  />
                )}
                <Typography
                  sx={{ fontSize: 22, marginLeft: "8px", color: "#000000" }}
                >
                  Inventory Codes
                </Typography>
              </button>

              {invExpanded && (
                <div style={{ padding: "8px 16px" }}>
                  <Stack
                    direction="row"
                    style={{ width: "95%", margin: "5px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        marginRight: "8px",
                        color: "#000000",
                      }}
                    >
                      Inventory Code Count:
                    </Typography>
                    <Typography sx={{ fontSize: 16, color: "#000000" }}>
                      {tableDetail["inventory_code"].length}
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 16, color: "#000000" }}>
                    {tableDetail["inventory_code"].join(" | ")}
                  </Typography>
                </div>
              )}
            </div>

            {/* Sample Section */}
            <div role="region" aria-labelledby="sample-section">
              <button
                id="sample-section"
                aria-expanded={sampleExpanded}
                onClick={() => setSampleExpanded(!sampleExpanded)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    setSampleExpanded(!sampleExpanded);
                  }
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {sampleExpanded ? (
                  <ExpandMoreIcon
                    fontSize="large"
                    style={{ color: "#9b51e0" }}
                  />
                ) : (
                  <ChevronRightIcon
                    fontSize="large"
                    style={{ color: "#9b51e0" }}
                  />
                )}
                <Typography
                  sx={{ fontSize: 22, marginLeft: "8px", color: "#000000" }}
                >
                  Sample
                </Typography>
              </button>

              {sampleExpanded && tableDetail && (
                <div style={{ padding: "8px 16px" }}>
                  {Object.keys(tableDetail.data).map((row, index) => (
                    <Stepper nonLinear style={{ padding: "2px" }} key={index}>
                      <Step completed={false}>
                        <Typography sx={{ fontSize: 14, color: "#000000" }}>
                          {row}
                        </Typography>
                      </Step>
                      <Step completed={row.status === "COMPLETED"}>
                        <Typography sx={{ fontSize: 14, color: "#000000" }}>
                          {tableDetail.data[row] || "null"}
                        </Typography>
                      </Step>
                    </Stepper>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {open && (
        <div
          role="presentation"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setOpen(false)}
        >
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="confirmation-title"
            aria-describedby="confirmation-description"
            style={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "24px",
              maxWidth: "400px",
              width: "90%",
              position: "relative",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Typography
              variant="h2"
              color="secondary"
              id="confirmation-title"
              sx={{
                marginBottom: "20px",
                paddingRight: "24px",
                fontSize: "1.25rem",
                fontWeight: 500,
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? "#000000"
                    : "#000000",
              }}
            >
              Do you want to accession samples?
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
                marginTop: "24px",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    setOpen(false);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleCheckDuplicates}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    handleCheckDuplicates();
                  }
                }}
                startIcon={<SaveAltIcon />}
              >
                Accession
              </Button>
            </Box>
          </div>
        </div>
      )}
    </Paper>
  );
}
