import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  TextField,
  Stack,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Alert,
  Grid,
  IconButton
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import BarcodeReader from "react-barcode-reader";
import AcsService from "../../services/AcsService";
import { useTheme } from "@mui/material/styles";

const AcsBarcodeScanning = (props) => {
  const [invCode, setInvCode] = React.useState("");
  const [errInvCode, setErrInvCode] = React.useState("");
  const [alertInv, setAlertInv] = React.useState(false);
  const [alertDuplicateInv, setAlertDublicateInv] = React.useState(false);
  const [pageLarge, setPageLarge] = React.useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = React.useState(10);

  const theme = useTheme();

  const tableRowData = [
    "Inventory Code",
    "Client",
    "Project",
    "Tracking Number",
    "Site",
    "Carrier",
  ];
  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };
  useEffect(() => {
    props.setScannedBarcodes({
      client: props.client,
      project: props.project,
      trackingNumber: props.trackingNumber,
      site: props.formListHeader.site,
      carrier: props.formListHeader.carrier,
      inventory_code: [],
    });
  }, [
    props.client,
    props.project,
    props.trackingNumber,
    props.formListHeader.site,
    props.formListHeader.carrier,
  ]);
  const handleError = (err) => {
    console.error(err);
  };
  const handleScan = async (data) => {
    setAlertDublicateInv(false);
    await AcsService.validateScannedBarcode(
      props.client,
      props.project,
      props.trackingNumber,
      data
    )
      .then((resp) => {
        props.scannedBarcodes["inventory_code"].includes(data)
          ? setAlertDublicateInv(true)
          : props.scannedBarcodes["inventory_code"].push(data);
        setInvCode("");
        setErrInvCode("");
        setAlertInv(false);
      })
      .catch((err) => {
        setAlertInv(true);
        setAlertDublicateInv(false);
        setErrInvCode(err.response.data.detail);
        setInvCode("");
      });
  };
  const handleInvCodeChange = (e) => {
    setInvCode(e.target.value);
  };
  const onKeyDown = (event) => {
    
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      event.stopPropagation();
      AddBarcodeChange();
    }
  };
  const AddBarcodeChange = () => {
    setAlertDublicateInv(false);
    AcsService.validateScannedBarcode(
      props.client,
      props.project,
      props.trackingNumber,
      invCode
    )
      .then((resp) => {
        props.scannedBarcodes["inventory_code"].includes(invCode)
          ? setAlertDublicateInv(true)
          : props.scannedBarcodes["inventory_code"].push(invCode);

        setInvCode("");
        setErrInvCode("");
        setAlertInv(false);
      })
      .catch((err) => {
        setAlertInv(true);
        setAlertDublicateInv(false);
        setErrInvCode(err.response.data.detail);
        setInvCode("");
      });
  };

  return (
    <Stack>
      <FormControl
        style={{
          paddingLeft: "35px",
          paddingRight: "35px",
          paddingBottom: "15px",
          paddingTop: "15px",
        }}
      >
        <Stack direction="row" style={{ width: "100%" }}>
          <BarcodeReader onError={handleError} onScan={handleScan} />
          <TextField
            onKeyDown={onKeyDown}
            label={"Inventory Code"}
            type="text"
            variant="outlined"
            style={{
              textAlign: "center",
              color: "white",
              margin: "0 10px 0 0",
              width: "100%",
            }}
            size="small"
            value={invCode}
            name="invCode"
            onChange={handleInvCodeChange}
          />
          <Button
  variant="contained"
  size="small"
  aria-disabled={!invCode} 
  role="button"
  tabIndex={0} 
  onClick={(e) => {
    if (!invCode) {
      e.preventDefault(); 
      return;
    }
    AddBarcodeChange(); 
  }}
  aria-label={
    !invCode
      ? "Add barcode - Enter inventory code first"
      : "Add barcode to list"
  } 

  sx={{
    "&[aria-disabled='true']": {
      pointerEvents: "none", 
      opacity: 0.7, 
      cursor: "not-allowed",
      color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.56)", 
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.12)", 
    },
    "&:not([aria-disabled='true'])": {
      cursor: "pointer", 
      color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff", 
      backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main, 
    },
    "&:focus": {
      outline: "2px solid",
      outlineColor: "primary.main", 
      outlineOffset: "2px",
    },
    "&:focus:not(:focus-visible)": {
      outline: "none", 
    },
    "&.Mui-focusVisible": {
      outline: "2px solid",
      outlineColor: "primary.main", 
      outlineOffset: "2px",
    },
  }}
>
  Add
</Button>
        </Stack>
        <Box sx={{ width: "100%" }}>
          {alertDuplicateInv && (
            <Alert style={{ marginTop: "10px" }} severity="error">
              Inventory Code is already scanned
            </Alert>
          )}
          {alertInv && (
            <Alert style={{ marginTop: "10px" }} severity="error">
              {errInvCode}
            </Alert>
          )}
          <Grid
            // container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {props.scannedBarcodes && (
              <TableContainer style={{ marginTop: "30px" }}>
                <Table
                  stickyHeader={true}
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size={"small"}
                >
                  <TableHead sx={{ minWidth: 650 }}>
                    <TableRow sx={{ minWidth: 650 }}>
                      {tableRowData.map((row, index) => (
                        <TableCell
                          align="left"
                          key={index}
                          style={{ fontWeight: "bold" }}
                        >
                          {row}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: "none" }}>
                    {props.scannedBarcodes.inventory_code !== 0 &&
                      props.scannedBarcodes.inventory_code
                        .slice(
                          pageLarge * rowsPerPageLarge,
                          pageLarge * rowsPerPageLarge + rowsPerPageLarge
                        )
                        .map((row, index) => (
                          <TableRow
                            hover={true}
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              {row || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {props.scannedBarcodes.client || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {props.scannedBarcodes.project || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {props.scannedBarcodes.trackingNumber ||
                                "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {props.scannedBarcodes.site || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {props.scannedBarcodes.carrier || "None / Null"}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                
                <TablePagination
  rowsPerPageOptions={[10, 20, 30]}
  component="div"
  count={props.scannedBarcodes.inventory_code.length}
  rowsPerPage={rowsPerPageLarge}
  page={pageLarge}
  onPageChange={handleChangePageLarge}
  onRowsPerPageChange={handleChangeRowsPerPageLarge}
  sx={{
    "& .MuiTablePagination-select": {
      "&:focus": {
        boxShadow: `0 0 0 3px ${
          theme.palette.mode === "light" ? "#000000" : "#FFFFFF"
        }`,
      },
    },
  }}
  ActionsComponent={(props) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={() => {
          if (props.page > 0) {
            props.onPageChange(null, props.page - 1);
          }
        }}
        aria-label="Previous page"
        tabIndex={0}
        aria-disabled={props.page === 0}
        onKeyDown={(e) => {
          if ((e.key === 'Enter' || e.key === ' ') && props.page > 0) {
            e.preventDefault();
            props.onPageChange(null, props.page - 1);
          }
        }}
        sx={{
          '&:focus': {
            outline: 'none',
            boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
          },
          '&[aria-disabled="true"]': {
            color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
            cursor: 'default',
          },
          '&[aria-disabled="false"]': {
            color: 'inherit',
          },
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={() => {
          if (props.page < Math.ceil(props.count / props.rowsPerPage) - 1) {
            props.onPageChange(null, props.page + 1);
          }
        }}
        aria-label="Next page"
        tabIndex={0}
        aria-disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
        onKeyDown={(e) => {
          if ((e.key === 'Enter' || e.key === ' ') &&
            props.page < Math.ceil(props.count / props.rowsPerPage) - 1) {
            e.preventDefault();
            props.onPageChange(null, props.page + 1);
          }
        }}
        sx={{
          '&:focus': {
            outline: 'none',
            boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
          },
          '&[aria-disabled="true"]': {
            color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)',
          },
          '&[aria-disabled="false"]': {
            color: 'inherit',
          },
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  )}
/>
              </TableContainer>
            )}
          </Grid>
        </Box>
      </FormControl>
    </Stack>
  );
};

export default AcsBarcodeScanning;
