import { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Divider,
  Paper,
} from "../../mui";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const tableRowData = [
  "Col Name",
  "Client",
  "Project",
  "Inventory Code",
  "Prev Data",
  "New Data",
  "Table",
  "Modified Date",
  "Created By",
  "Reason",
];
const DropDownCardHistory = (props) => {
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(6);
  const theme = useTheme();
  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  return (
    <Stack direction="column">
      <Box sx={{ width: "100%" }}>
        <Divider style={{ width: "100%", marginBottom: "10px" }}></Divider>
        <Grid
          // container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <TableContainer style={{ marginTop: "15px" }}>
            <Table
              stickyHeader={true}
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size={"small"}
            >
              <TableHead sx={{ minWidth: 650 }}>
                <TableRow sx={{ minWidth: 650 }}>
                  {tableRowData.map((row, index) => (
                    <TableCell
                      align="left"
                      key={index}
                      style={{ fontWeight: "bold" }}
                    >
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "none" }}>
                {props.data.length !== undefined &&
                  props.data
                    .slice(
                      pageLarge * rowsPerPageLarge,
                      pageLarge * rowsPerPageLarge + rowsPerPageLarge
                    )
                    .map((row, index) => (
                      <TableRow
                        hover={true}
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="left">
                          {row.col_name || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.client || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.project || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.inventory_code || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.prev_data || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.new_data || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.table || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.created_on || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.created_by || "None / Null"}
                        </TableCell>
                        <TableCell align="left">
                          {row.reason || "None / Null"}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[6, 10, 25]}
              component="div"
              count={props.data.length}
              rowsPerPage={rowsPerPageLarge}
              page={pageLarge}
              onPageChange={handleChangePageLarge}
              onRowsPerPageChange={handleChangeRowsPerPageLarge}
              labelRowsPerPage={
                <span
                  tabIndex={0}
                  role="status"
                  aria-label="Rows per page selector"
                  style={{
                    padding: "4px 8px",
                    borderRadius: "4px",
                    cursor: "default",
                  }}
                >
                  Rows per page:
                </span>
              }
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                  role: "combobox",
                },
                SelectDisplayProps: {
                  tabIndex: 0,
                },
                sx: {
                  "& .MuiSelect-select": {
                    paddingY: "8px",
                    minHeight: "auto",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 2,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&:focus-within": {
                    outline: `2px solid ${theme.palette.primary.main}`,
                    outlineOffset: "2px",
                    borderRadius: "4px",
                  },
                  "&.Mui-focused": {
                    outline: `2px solid ${theme.palette.primary.main}`,
                    outlineOffset: "2px",
                    borderRadius: "4px",
                  },
                },
              }}
              ActionsComponent={(props) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => {
                      if (props.page > 0) {
                        props.onPageChange(null, props.page - 1);
                      }
                    }}
                    aria-label="Previous page"
                    tabIndex={0}
                    aria-disabled={props.page === 0}
                    onKeyDown={(e) => {
                      if (
                        (e.key === "Enter" || e.key === " ") &&
                        props.page > 0
                      ) {
                        e.preventDefault();
                        props.onPageChange(null, props.page - 1);
                      }
                    }}
                    sx={{
                      "&:focus": {
                        outline: "none",
                        boxShadow: (theme) =>
                          `0 0 0 2px ${theme.palette.primary.main}`,
                      },
                      '&[aria-disabled="true"]': {
                        color:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.3)"
                            : "rgba(0, 0, 0, 0.26)",
                        cursor: "default",
                      },
                      '&[aria-disabled="false"]': {
                        color: "inherit",
                      },
                    }}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (
                        props.page <
                        Math.ceil(props.count / props.rowsPerPage) - 1
                      ) {
                        props.onPageChange(null, props.page + 1);
                      }
                    }}
                    aria-label="Next page"
                    tabIndex={0}
                    aria-disabled={
                      props.page >=
                      Math.ceil(props.count / props.rowsPerPage) - 1
                    }
                    onKeyDown={(e) => {
                      if (
                        (e.key === "Enter" || e.key === " ") &&
                        props.page <
                        Math.ceil(props.count / props.rowsPerPage) - 1
                      ) {
                        e.preventDefault();
                        props.onPageChange(null, props.page + 1);
                      }
                    }}
                    sx={{
                      "&:focus": {
                        outline: "none",
                        boxShadow: (theme) =>
                          `0 0 0 2px ${theme.palette.primary.main}`,
                      },
                      '&[aria-disabled="true"]': {
                        color:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.3)"
                            : "rgba(0, 0, 0, 0.26)",
                      },
                      '&[aria-disabled="false"]': {
                        color: "inherit",
                      },
                    }}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Box>
              )}
            />
          </TableContainer>
        </Grid>
      </Box>
      {/* )} */}
    </Stack>
  );
};

DropDownCardHistory.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
};

export default DropDownCardHistory;
