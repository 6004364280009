import UIWrapper from "../../../components/UIWrapper";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../../Store/snackbar";
import { Box } from "../../../mui";
import {
    DataGrid,
  } from "@mui/x-data-grid";
import GuideService from "../../../services/GuideService";

const Guides = () => {
    const [tableData, setTableData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const dispatch = useDispatch();

    const renderDownloadLinks = (params) => {
        const fileNameArray = params.value.split("/");
        const fileName = fileNameArray[fileNameArray.length - 1];
        return (
          <Typography
            variant="p"
            color="secondary.main"
            onClick={() => handleDownload(params.value)}
            title={fileName}
            sx={{
              cursor: "Pointer",
              textDecoration: "underline",
            }}
          >
            {fileName}
          </Typography>
        );
      };

  const SmpledGuideColumns = [
    {
      field: "file_name",
      headerName: "File Name",
      minWidth: 300,
      editable: false,
      renderCell: (params) => {
        return (
          <Typography variant="p" color="secondary.main" title={params.value}>
            {params.value}
          </Typography>
        );
      },

      valueGetter: (params) => {
        // if (!params.value) {
        //   return "None/Null";
        // }
        // return params.value;
        return params ?? "None/Null";
      },
    },
    
    {
      field: "file_path",
      headerName: "Download Link",
      minWidth: 350,
      editable: false,
      valueGetter: (params) => {
        // if (!params.value) {
        //   return "None/Null";
        // }
        // return params.value;
        return params ?? "None/Null";
      },
      renderCell: renderDownloadLinks,
    },
    
  ];

  useEffect(() => {
    getSampledGuideRecords();
    return;
  }, []);

  // get all records of SampledSphere Guides 
  const getSampledGuideRecords = async () => {
    setDataLoading(true);
    await GuideService.getSSguideRecords()
      .then((resp) => {
        setTableData(
            resp.data.map((row, id) => {
            return { id, ...row };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to get Sampled Guides Data.",
          })
        );
      });
    setDataLoading(false);
  };

  const handleDownload = (file) => {
    const fileName = file.split("/");
    GuideService.getSSGuideFile(fileName[fileName.length - 1])
      .then((resp) => {
        window.location.replace(resp.data.presigned_url);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Download",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Download",
          })
        );
      });
  };


  return (
    <UIWrapper title="SampledSphere Guides" paper>
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        {/* <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Total Number of Records: {tableData.length}
        </Typography> */}
        <Box
          height="375px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <DataGrid
            rows={tableData}
            columns={SmpledGuideColumns}
            loading={dataLoading}
            density="compact"
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </Box>
    </UIWrapper>
  );
};

export default Guides;
