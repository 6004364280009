import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import {
  Stack,
  Box,
  Button,
  TextField,
  Alert,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "../mui";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import CommonService from "../services/CommonService";
import { snackbarActions } from "../Store/snackbar";
import { useDebouncedCallback } from "use-debounce";
import { useTheme } from "@mui/material/styles";
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  maxWidth: "750px",
};

const tableRowData = ["Received BarCode", "Inventory Code"];

const ShowTableRow = ({
  row,
  index,
  setPropsData,
  uniqueList,
  service_name,
  setCheckCount,
}) => {
  const [rowData, setRowData] = useState(row);
  const loading = useRef(false);
  const source = useRef(axios.CancelToken.source());

  useEffect(() => setRowData(row), [row]);
  const debounced = useDebouncedCallback((newInvCode) => {
    {
      source.current?.token &&
        source.current?.cancel("Unwanted API request got cancelled");
    }
    source.current = axios.CancelToken.source();
    if (uniqueList.indexOf(newInvCode) === -1 && newInvCode !== "") {
      CommonService.validateBarCode(
        {
          inventory_code: newInvCode,
          client: rowData?.client,
          project: rowData?.project,
        },
        service_name,
        source.current
      )
        .then((resp) => {
          setPropsData((prev) => {
            return {
              data: prev.data.map((val, pos) => {
                if (pos === index) {
                  return {
                    ...val,
                    inventory_code: newInvCode,
                    duplicate_inventory_code: resp.data.is_exist,
                    kitting_input: resp.data?.is_kitting,
                  };
                } else return val;
              }),
              duplicate_count:
                prev.data[index].duplicate_inventory_code === resp.data.is_exist
                  ? prev.duplicate_count
                  : resp.data.is_exist
                  ? prev.duplicate_count + 1
                  : prev.duplicate_count - 1,
            };
          });
          loading.current = false;
          setCheckCount((prev) => prev - 1);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setPropsData((prev) => prev);
            loading.current = false;
            setCheckCount((prev) => prev - 1);
          }

          console.log(err);
        });
    } else if (
      uniqueList.indexOf(newInvCode) > -1 &&
      newInvCode === row.old_inventory_code
    ) {
      setPropsData((prev) => {
        const updatedData = {
          data: prev.data.map((val, pos) => {
            if (pos === index) {
              return {
                ...val,
                inventory_code: newInvCode,
                duplicate_inventory_code: false,
                kitting_input: false,
              };
            } else return val;
          }),
          duplicate_count: prev.data[index].duplicate_inventory_code
            ? prev.duplicate_count - 1
            : prev.duplicate_count,
        };
        return updatedData;
      });
      loading.current = false;
      setCheckCount((prev) => prev - 1);
    } else {
      setPropsData((prev) => {
        const updatedData = {
          data: prev.data.map((val, pos) => {
            if (pos === index) {
              return {
                ...val,
                inventory_code: newInvCode,
                duplicate_inventory_code: true,
                kitting_input: false,
              };
            } else return val;
          }),
          duplicate_count: prev.data[index].duplicate_inventory_code
            ? prev.duplicate_count
            : prev.duplicate_count + 1,
        };
        return updatedData;
      });
      loading.current = false;
      setCheckCount((prev) => prev - 1);
    }
  }, 2500);

  const handleInvCodeChange = (e) => {
    e.preventDefault();
    if (!loading.current) {
      loading.current = true;
      setCheckCount((prev) => prev + 1);
    }
    setRowData({
      ...rowData,
      inventory_code: e.target.value,
    });
    debounced(e.target.value.trim());
  };

  return (
    <TableRow
      hover={true}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell align="left">
        <TextField
          id="outlined-read-only-input"
          value={rowData.received_barcode || ""}
          size="small"
          width="95%"
          sx={{
            "& .MuiOutlinedInput-root": {
              bgcolor: rowData.kitting
                ? "yellow.main"
                : rowData.duplicate_barcode
                ? "#f6a5c0"
                : "green.main",

              "& input": {
                padding: "8px 14px",
                height: "16px",
                minHeight: "16px",
              },
            },
          }}
          InputProps={{
            readOnly: true,
          }}
          label={<span style={{ display: "none" }}>Received Barcode</span>}
        />
      </TableCell>
      <TableCell align="left">
        <TextField
          id="outlined-controlled"
          value={rowData.inventory_code || ""}
          size="small"
          width="95%"
          sx={
            rowData?.kitting_input &&
            !rowData.duplicate_inventory_code &&
            !loading.current
              ? {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "yellow.main",
                    },
                  },
                  "& label,label.Mui-focused": {
                    color: "yellow.main",
                  },
                }
              : null
          }
          onChange={handleInvCodeChange}
          error={loading.current ? false : rowData.duplicate_inventory_code}
          label={
            loading.current
              ? "Loading..."
              : rowData.duplicate_inventory_code
              ? "Please enter a unique InvCode"
              : rowData?.kitting_input
              ? "Entered Value exists in the LIMS."
              : "Inventory Code"
          }
        />
      </TableCell>
    </TableRow>
  );
};

const AcsUniqueInvCode = ({
  loading,
  tableBarCodeData,
  hamdleParentSubmission,
  processing,
  service_name,
}) => {
  const [uniquifying, setUniquifying] = useState(false);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(50);
  const dispatch = useDispatch();
  const [showKittingWarning, setShowKittingWarning] = useState(false);

  const [propsData, setPropsData] = useState(tableBarCodeData);
  const [checkCount, setCheckCount] = useState(0);
  const theme = useTheme();
  const uniqueList = useRef([]);
  let tempList = [];
  propsData?.data?.map((item) => {
    if (
      item.duplicate_inventory_code === false &&
      item.inventory_code !== item.old_inventory_code
    ) {
      tempList.push(item.inventory_code);
    }
  });
  tempList = [...tempList, ...uniqueList.current];

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(event.target.value);
    setPageLarge(0);
  };

  const handleUniquification = () => {
    setUniquifying(true);
    const cleanedData = {
      data: propsData?.data.map((el) => {
        if (el?.kitting_input & el.duplicate_inventory_code)
          return { ...el, kitting_input: false };
        else return el;
      }),
      duplicate_count: propsData.duplicate_count,
    };
    CommonService.generateUniqueBarcodes(cleanedData, service_name)
      .then((resp) => {
        setPropsData(resp.data);
        setUniquifying(false);
      })
      .catch((err) => {
        console.log(err);
        setUniquifying(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };
  useEffect(() => {
    const formattedData =
      service_name === "accession_manifest"
        ? tableBarCodeData?.data?.map((item) => {
            if (item.duplicate_barcode === false)
              uniqueList.current.push(item.received_barcode);
            return {
              ...item,
              old_inventory_code: item.received_barcode,
            };
          })
        : tableBarCodeData?.data?.map((item) => {
            if (item.duplicate_barcode === false)
              uniqueList.current.push(item.old_inventory_code);
            return {
              ...item,
              inventory_code: item.duplicate_barcode
                ? ""
                : item.old_inventory_code,
            };
          });
    setPropsData({
      data: formattedData,
      duplicate_count: tableBarCodeData?.duplicate_count,
    });
    setShowKittingWarning(tableBarCodeData?.data?.some((el) => el.kitting));
  }, [tableBarCodeData]);
  const handleProceed = () => {
    if (propsData?.duplicate_count === 0) {
      hamdleParentSubmission(propsData);
    }
  };

  return (
    <Stack sx={style}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{ maxWidth: "700px", marginTop: "20px" }}
          overflow="auto"
          component={Paper}
          variant="outlined"
        >
          <Grid
            style={{
              textAlign: "center",
              marginTop: "5px",
            }}
            flex
            justifyContent="center"
            alignItems="center"
            container
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              m="0 1rem"
            >
              {uniquifying ? (
                <Box sx={{ flexDirection: "row" }}>
                  <Button
                    variant="contained"
                    aria-disabled="true"
                    type="button"
                    role="button"
                    tabIndex={0}
                    aria-label="Processing inventory code uniquification"
                    sx={{
                      '&[aria-disabled="true"]': {
                        pointerEvents: "none",
                        color:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.3)"
                            : "rgba(0, 0, 0, 0.26)",
                        borderColor: "rgba(0, 0, 0, 0.12)",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.12)"
                            : "rgba(0, 0, 0, 0.12)",
                      },
                      "&:focus": {
                        outline: "2px solid",
                        outlineColor: "primary.main",
                        outlineOffset: "2px",
                      },
                      "&:focus:not(:focus-visible)": {
                        outline: "none",
                      },
                      "&.Mui-focusVisible": {
                        outline: "2px solid",
                        outlineColor: "primary.main",
                        outlineOffset: "2px",
                      },
                    }}
                  >
                    Processing
                    <CircularProgress
                      style={{ margin: "0 0 0 5px" }}
                      color="inherit"
                      size={15}
                      aria-label="Processing indicator"
                    />
                  </Button>
                </Box>
              ) : (
                <Button
                  onClick={handleUniquification}
                  aria-disabled={
                    !propsData?.data?.length ||
                    propsData?.duplicate_count === 0 ||
                    checkCount > 0
                  }
                  type="button"
                  variant="contained"
                  role="button"
                  tabIndex={0}
                  aria-label={
                    !propsData?.data?.length
                      ? "Make inventory code unique - No data available"
                      : propsData?.duplicate_count === 0
                      ? "Make inventory code unique - No duplicates found"
                      : checkCount > 0
                      ? "Make inventory code unique - Validation in progress"
                      : "Make inventory code unique"
                  }
                  sx={{
                    '&[aria-disabled="true"]': {
                      pointerEvents: "none",

                      color:
                        theme.palette.mode === "dark"
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(0, 0, 0, 0.26)",
                      borderColor: "rgba(0, 0, 0, 0.12)",
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "rgba(255, 255, 255, 0.12)"
                          : "rgba(0, 0, 0, 0.12)",
                    },
                    '&:not([aria-disabled="true"])': {
                      cursor: "pointer",
                    },
                    "&:focus": {
                      outline: "2px solid",
                      outlineColor: "primary.main",
                      outlineOffset: "2px",
                    },
                    "&:focus:not(:focus-visible)": {
                      outline: "none",
                    },
                    "&.Mui-focusVisible": {
                      outline: "2px solid",
                      outlineColor: "primary.main",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  Make Inv. Code Unique
                </Button>
              )}
              <Stack direction="row" gap={2}>
                <Stack direction="row" gap={1}>
                  <Box
                    sx={{
                      height: "1.2rem",
                      width: "1.2rem",
                      borderRadius: "50%",
                      bgcolor: "green.main",
                    }}
                  ></Box>
                  <Typography>Unique</Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <Box
                    sx={{
                      height: "1.2rem",
                      width: "1.2rem",
                      borderRadius: "50%",
                      bgcolor: "yellow.main",
                    }}
                  ></Box>
                  <Typography>Kitting</Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <Box
                    sx={{
                      height: "1.2rem",
                      width: "1.2rem",
                      borderRadius: "50%",
                      bgcolor: "#f6a5c0",
                    }}
                  ></Box>
                  <Typography>Duplicate</Typography>
                </Stack>
              </Stack>
            </Box>

            <TableContainer
              style={{ marginTop: "15px", width: "98%", maxHeight: "55vh" }}
            >
              <Table
                stickyHeader={true}
                aria-label="simple table"
                size={"small"}
              >
                <TableHead>
                  <TableRow>
                    {tableRowData.map((row, index) => (
                      <TableCell
                        align="left"
                        key={index}
                        style={{ fontWeight: "bold" }}
                      >
                        {row}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading === false && (
                  <TableBody style={{ border: "none" }}>
                    {propsData?.data?.length !== undefined &&
                      propsData.data
                        .slice(
                          pageLarge * rowsPerPageLarge,
                          pageLarge * rowsPerPageLarge + rowsPerPageLarge
                        )
                        .map((row, index) => (
                          <ShowTableRow
                            row={row}
                            key={
                              row.received_barcode +
                              pageLarge * rowsPerPageLarge
                            }
                            index={index + pageLarge * rowsPerPageLarge}
                            setPropsData={setPropsData}
                            uniqueList={tempList}
                            service_name={service_name}
                            setCheckCount={setCheckCount}
                          />
                        ))}
                  </TableBody>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={propsData?.data?.length || 0}
                rowsPerPage={rowsPerPageLarge}
                page={pageLarge}
                onPageChange={handleChangePageLarge}
                onRowsPerPageChange={handleChangeRowsPerPageLarge}
                labelRowsPerPage={
                  <span
                    tabIndex={0}
                    role="status"
                    aria-label="Rows per page selector"
                    style={{
                      padding: "4px 8px",
                      borderRadius: "4px",
                      cursor: "default",
                    }}
                  >
                    Rows per page:
                  </span>
                }
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                    role: "combobox",
                  },
                  SelectDisplayProps: {
                    tabIndex: 0,
                  },
                  sx: {
                    "& .MuiSelect-select": {
                      paddingY: "8px",
                      minHeight: "auto",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.primary.main,
                      borderWidth: 2,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.primary.main,
                    },
                    "&:focus-within": {
                      outline: `2px solid ${theme.palette.primary.main}`,
                      outlineOffset: "2px",
                      borderRadius: "4px",
                    },
                    "&.Mui-focused": {
                      outline: `2px solid ${theme.palette.primary.main}`,
                      outlineOffset: "2px",
                      borderRadius: "4px",
                    },
                  },
                }}
                ActionsComponent={(props) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={() => {
                        if (props.page > 0) {
                          props.onPageChange(null, props.page - 1);
                        }
                      }}
                      aria-label="Previous page"
                      tabIndex={0}
                      aria-disabled={props.page === 0}
                      onKeyDown={(e) => {
                        if (
                          (e.key === "Enter" || e.key === " ") &&
                          props.page > 0
                        ) {
                          e.preventDefault();
                          props.onPageChange(null, props.page - 1);
                        }
                      }}
                      sx={{
                        "&:focus": {
                          outline: "none",
                          boxShadow: (theme) =>
                            `0 0 0 2px ${theme.palette.primary.main}`,
                        },
                        '&[aria-disabled="true"]': {
                          color:
                            theme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.3)"
                              : "rgba(0, 0, 0, 0.26)",
                          cursor: "default",
                        },
                        '&[aria-disabled="false"]': {
                          color: "inherit",
                        },
                      }}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (
                          props.page <
                          Math.ceil(props.count / props.rowsPerPage) - 1
                        ) {
                          props.onPageChange(null, props.page + 1);
                        }
                      }}
                      aria-label="Next page"
                      tabIndex={0}
                      aria-disabled={
                        props.page >=
                        Math.ceil(props.count / props.rowsPerPage) - 1
                      }
                      onKeyDown={(e) => {
                        if (
                          (e.key === "Enter" || e.key === " ") &&
                          props.page <
                            Math.ceil(props.count / props.rowsPerPage) - 1
                        ) {
                          e.preventDefault();
                          props.onPageChange(null, props.page + 1);
                        }
                      }}
                      sx={{
                        "&:focus": {
                          outline: "none",
                          boxShadow: (theme) =>
                            `0 0 0 2px ${theme.palette.primary.main}`,
                        },
                        '&[aria-disabled="true"]': {
                          color:
                            theme.palette.mode === "dark"
                              ? "rgba(255, 255, 255, 0.3)"
                              : "rgba(0, 0, 0, 0.26)",
                        },
                        '&[aria-disabled="false"]': {
                          color: "inherit",
                        },
                      }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                )}
              />
            </TableContainer>
            {loading === true && (
              <Box height="7rem" justifyContent="center" alignItems="center">
                <CircularProgress size={"5rem"} />
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
      {((!loading && propsData?.duplicate_count !== 0) ||
        propsData?.data?.length === 0) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="10px"
        >
          <Alert
            style={{ top: "30%" }}
            // onClose={() => setShowWarning(false)}
            severity="warning"
          >
            {propsData?.data?.length === undefined ||
            propsData?.data?.length === 0
              ? "No inventory code data was found for processing"
              : `Found ${propsData?.duplicate_count} duplicate
                  inventory codes. Please make them unique.`}
          </Alert>
        </Box>
      )}
      {showKittingWarning && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="5px"
        >
          <Alert
            style={{ top: "30%" }}
            onClose={() => setShowKittingWarning(false)}
            severity="warning"
          >
            Inventory Code for the row marked in Yellow already exist in the
            LIMS. Please verify if this is correct for Kitting or not.
          </Alert>
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          style={{ maxWidth: "600px", marginTop: "5px" }}
          aria-disabled={
            loading ||
            checkCount > 0 ||
            processing ||
            propsData?.duplicate_count > 0 ||
            !propsData?.data?.length
          }
          type="button"
          role="button"
          tabIndex={0}
          variant="contained"
          onClick={handleProceed}
          aria-label={
            loading
              ? "Proceed - Loading in progress"
              : checkCount > 0
              ? "Proceed - Validation in progress"
              : processing
              ? "Proceed - Processing in progress"
              : propsData?.duplicate_count > 0
              ? "Proceed - Duplicate inventory codes found"
              : !propsData?.data?.length
              ? "Proceed - No data available"
              : "Proceed with inventory code submission"
          }
          sx={{
            '&[aria-disabled="true"]': {
              pointerEvents: "none",
              cursor: "not-allowed",
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.26)",
              borderColor: "rgba(0, 0, 0, 0.12)",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.12)"
                  : "rgba(0, 0, 0, 0.12)",
            },
            '&:not([aria-disabled="true"])': {
              cursor: "pointer",
            },
            "&:focus": {
              outline: "2px solid",
              outlineColor: "primary.main",
              outlineOffset: "2px",
            },
            "&:focus:not(:focus-visible)": {
              outline: "none",
            },
            "&.Mui-focusVisible": {
              outline: "2px solid",
              outlineColor: "primary.main",
              outlineOffset: "2px",
            },
          }}
        >
          Proceed
        </Button>
      </Box>
    </Stack>
  );
};

export default AcsUniqueInvCode;
