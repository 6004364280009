import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "../../mui";

function toCamelcase(headerName) {
  return headerName?.replace(/(?:_| |\b)(\w)/g, function (name) {
    return name.toUpperCase().replace("_", "");
  });
}

const PreviewTable = ({ content, emptyTitle }) => {
  console.log("Here's Content =>>>>", content);
  return (
    <Box sx={{ overflow: "hidden", maxWidth: "98%" }}>
      {content ? (
        <TableContainer component={Paper}>
          <Table
            aria-label="simple table"
            size={"small"}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <TableHead>
              <TableRow>
                {Object.keys(content[0]).map((row, index) => (
                  <TableCell
                    align="left"
                    key={index}
                    style={{ fontWeight: "bold" }}
                    component="td"
                    tabIndex={0}
                    role="gridcell"
                    aria-label={`${toCamelcase(row)}`}
                  >
                    {toCamelcase(row)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ border: "none" }}>
              {content.slice(0, 10).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  {Object.keys(row).map((key) => (
                    <TableCell
                      align="left"
                      key={key}
                      component="td"
                      tabIndex={0}
                      role="gridcell"
                      aria-label={`${row[key]}`}
                    >
                      {row[key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          component={Paper}
          variant={"outlined"}
        >
          <Typography color="secondary" tabIndex={0} role="status">
            {emptyTitle ? emptyTitle : "PreviewData"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PreviewTable;
