import { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import AcsService from "../../services/AcsService";
import { Typography, Box, IconButton, Stack, Button } from "../../mui";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { snackbarActions } from "../../Store/snackbar";
import { useDispatch } from "react-redux";
import { Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Custom focus styles
const focusStyles = {
  outline: "2px solid #1976d2",
  outlineOffset: "2px",
  boxShadow: "0 0 0 4px rgba(25, 118, 210, 0.2)",
  borderRadius: "4px",
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
  const rowCount = apiRef.current.getRowsCount();

  const theme = useTheme();
  // Calculate current range of visible rows
  const startRow = page * pageSize + 1;
  const endRow = Math.min((page + 1) * pageSize, rowCount);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        padding: "8px 16px",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <label
          id="rows-per-page-label"
          htmlFor="rows-per-page-select"
          style={{
            display: "flex",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "0.8rem",
            fontWeight: "400",
            lineHeight: "1.43",
            margin: "0",
            padding: "0",
          }}
        >
          <span
            tabIndex={0}
            role="status"
            aria-label="Rows per page selector"
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              cursor: "default",
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.buttonDefault.main
                  : theme.palette.black.main,
            }}
            sx={{
              "&:focus": {
                outline: "2px solid #9b51e0",
                outlineOffset: "2px",
                backgroundColor: "rgba(155, 81, 224, 0.08)",
              },
              "&:focus-visible": {
                outline: "2px solid #9b51e0",
                outlineOffset: "2px",
                backgroundColor: "rgba(155, 81, 224, 0.08)",
              },
            }}
          >
            Rows per page:
          </span>
        </label>
        <Select
          id="rows-per-page-select"
          value={pageSize}
          onChange={(e) => apiRef.current.setPageSize(Number(e.target.value))}
          labelId="rows-per-page-label"
          aria-labelledby="rows-per-page-label"
          inputProps={{
            "aria-label": "Rows per page",
          }}
          sx={{
            marginLeft: "8px",

            "& .MuiSelect-select": {
              padding: "4px 8px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:focus": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "2px",
            },
          }}
        >
          {[25, 50, 100].map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Typography
        variant="body2"
        tabIndex={0}
        sx={{ mx: 2, ml: 2 }}
        role="status"
        aria-live="polite"
      >
        {startRow}-{endRow} of {rowCount}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            if (page > 0) {
              apiRef.current.setPage(page - 1);
            }
          }}
          // Remove the disabled prop to keep it focusable
          // disabled={page === 0}
          size="small"
          aria-label="Previous page"
          // Always keep it in the tab order
          tabIndex={0}
          // Use aria-disabled for accessibility
          aria-disabled={page === 0}
          onKeyDown={(e) => {
            // Allow keyboard activation with Enter or Space
            if ((e.key === "Enter" || e.key === " ") && page > 0) {
              e.preventDefault();
              apiRef.current.setPage(page - 1);
            }
          }}
          sx={{
            "&:focus": {
              outline: "none",
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
            },
            '&[aria-disabled="true"]': {
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.26)",
              cursor: "default",
            },
            '&[aria-disabled="false"]': {
              color: "inherit",
            },
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          onClick={() => {
            if (page < pageCount - 1) {
              apiRef.current.setPage(page + 1);
            }
          }}
          aria-disabled={page >= pageCount - 1}
          size="small"
          aria-label="Next page"
          tabIndex={0}
          sx={{
            "&:focus": {
              outline: "none",
              boxShadow: (theme) => `0 0 0 2px ${theme.palette.primary.main}`,
            },
            '&[aria-disabled="true"]': {
              color:
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.26)",
            },
            '&[aria-disabled="false"]': {
              color: "inherit",
            },
          }}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
}

function CustomToolbar() {
  const [fileName, setFileName] = useState("");
  const generateFileName = () => {
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);
    date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
    date = date.replace(/\s/g, "");
    date = date.slice(0, -2);
    setFileName("on_hold_sample_export_" + date + ".csv");
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Box onClick={generateFileName}>
        <GridToolbarExport
          csvOptions={{
            fileName: fileName,
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
}

const onHoldColumns = [
  {
    field: "client",
    headerName: "Client",
    maxWidth: 50,
    //flex: 1,
    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "project",
    headerName: "Project",
    maxWidth: 50,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "inventory_code",
    headerName: "Inventory Code",
    maxWidth: 100,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "tracking_number",
    headerName: "Tracking Number",
    maxWidth: 100,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "carrier",
    headerName: "Carrier",
    maxWidth: 50,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "storage_location",
    headerName: "Storage Location",
    maxWidth: 100,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "temperature",
    headerName: "Temperature",
    maxWidth: 70,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "tube_type",
    headerName: "Tube Type",
    maxWidth: 80,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "date_disposition",
    headerName: "Date Disposition",
    maxWidth: 80,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "date_received",
    headerName: "Date Received",
    maxWidth: 80,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "created_on",
    headerName: "Created On",
    maxWidth: 80,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "created_by",
    headerName: "Created By",
    maxWidth: 100,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "final_disposition",
    headerName: "Final Disposition",
    maxWidth: 100,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    maxWidth: 90,

    editable: false,
    valueGetter: (params) => {
      return params ?? "None/Null";
    },
  },
];

const OnHoldSearch = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    setDataLoading(true);

    const getSample = async () => {
      await AcsService.getOnHoldSamples()
        .then((resp) => setTableData(resp.data))
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
      setDataLoading(false);
    };
    getSample();

    return;
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "20px",
        // Add focus styles for the container
        "&:focus-within": {
          ...focusStyles,
        },
      }}
    >
      <Typography
        sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}
        tabIndex={0}
        role="status"
        aria-live="polite"
      >
        Total Number of On-Hold Samples: {tableData.length}
      </Typography>

      <DataGrid
        rows={tableData}
        columns={onHoldColumns.map((column) => ({
          ...column,
          renderCell: (params) => (
            <div
              className="cell-content"
              tabIndex={0}
              role="button"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  if (column.onCellClick) {
                    column.onCellClick(params);
                  }
                }
              }}
              onClick={() => {
                if (column.onCellClick) {
                  column.onCellClick(params);
                }
              }}
              aria-label={`${column.headerName}: ${params.value || ""}`}
            >
              {params.value}
            </div>
          ),
        }))}
        loading={dataLoading}
        density="compact"
        slots={{
          toolbar: CustomToolbar,
          pagination: CustomPagination,
        }}
        getRowId={(row) => row.id}
        disableColumnMenu
        componentsProps={{
          row: {
            tabIndex: 0, // Make rows focusable
            onKeyDown: (event) => {
              // Allow Enter and Space to trigger row action
              if (event.key === "Enter" || event.key === " ") {
                event.preventDefault(); // Prevent default to avoid scrolling
              }
            },
            onFocus: (event) => {
              event.currentTarget.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
            },
          },
          cell: {
            tabIndex: 0, // Make cells focusable
            onKeyDown: (event) => {
              // Allow Enter and Space to trigger cell action if needed
              if (event.key === "Enter" || event.key === " ") {
                event.preventDefault(); // Prevent default to avoid scrolling
                // Handle cell action if necessary
              }
            },
            onFocus: (event) => {
              event.currentTarget.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
            },
          },
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell": {
            outline: "none",
            cursor: "pointer",
            "&:focus, &:focus-within, &:focus-visible": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "-2px",
            },
            '&[role="cell"], &[role="gridcell"]': {
              cursor: "pointer",
              tabIndex: 0,
              "&:focus": {
                outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                outlineOffset: "-2px",
              },
            },
          },
          "& .cell-content": {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "8px",
            "&:focus": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "-2px",
            },
          },
          "& .MuiDataGrid-cell": {
            padding: 0,
            height: "36px !important", // Force 36px height
            "&:focus-within": {
              outline: "none",
            },
          },
          "& .MuiDataGrid-row": {
            height: "36px !important", // Force 36px height
            maxHeight: "36px !important", // Ensure max height is also 36px
            "&:focus, &:focus-within": {
              outline: "none",
            },
          },
          // Override the internal row height
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              height: "36px !important",
              maxHeight: "36px !important",
            },
          },
          // Ensure consistent row height in all states
          "& .MuiDataGrid-virtualScrollerContent": {
            "& .MuiDataGrid-row": {
              height: "36px !important",
              maxHeight: "36px !important",
            },
          },
          "& .MuiDataGrid-columnHeader": {
            "&:focus, &:focus-within": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "-2px",
            },
          },
          // Remove outline from grid container elements
          "& .MuiDataGrid-main, & .MuiDataGrid-virtualScroller, & .MuiDataGrid-virtualScrollerContent, & .MuiDataGrid-virtualScrollerRenderZone, & .MuiDataGrid-footerContainer, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeadersInner":
            {
              outline: "none",
            },
        }}
      />

      {/* Keyboard-accessible actions panel */}
      {selectedRow && (
        <Box
          role="region"
          aria-label="Selected row actions"
          tabIndex={0}
          sx={{
            mt: 2,
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "4px",
            "&:focus": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "2px",
            },
          }}
        >
          <Typography variant="h6" gutterBottom>
            Selected Sample: {selectedRow.id}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  // Add your view details action
                  console.log("View details:", selectedRow);
                }
              }}
              onClick={() => {
                // Add your view details action
                console.log("View details:", selectedRow);
              }}
              aria-label={`View details for sample ${selectedRow.id}`}
            >
              View Details
            </Button>
            <Button
              variant="outlined"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  setSelectedRow(null);
                }
              }}
              onClick={() => setSelectedRow(null)}
            >
              Clear Selection
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default OnHoldSearch;
