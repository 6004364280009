import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  TextField,
  Stack,
  Button,
  Grid,
  Paper,
  Typography,
  InputLabel,
  Select,
} from "@mui/material";
// import { makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import AcsService from "../../services/AcsService";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { userTimeZone, timeZonesList } from "../../components/helper/timeZone";
import { useTheme } from "@mui/material/styles";

const carrierTypes = [
  {
    value: "BIOCAIR",
    label: "BIOCAIR",
  },
  {
    value: "DHL",
    label: "DHL",
  },
  {
    value: "FedEx",
    label: "FedEx",
  },
  {
    value: "FedEx Ground",
    label: "FedEx Ground",
  },
  {
    value: "Hand Delivered",
    label: "Hand Delivered",
  },
  {
    value: "Marken",
    label: "Marken",
  },
  {
    value: "Priority Express",
    label: "Priority Express",
  },
  {
    value: "Quickstat",
    label: "Quickstat",
  },
  {
    value: "TNT",
    label: "TNT",
  },
  {
    value: "UPS",
    label: "UPS",
  },
  {
    value: "USA Courier",
    label: "USA Courier",
  },
  {
    value: "USPS",
    label: "USPS",
  },
  {
    value: "VIP Cargo",
    label: "VIP Cargo",
  },
  {
    value: "World Courrier",
    label: "World Courrier",
  },
];

const steps = ["Client", "Project", "Subject Code", "Visit", "Tracking Number"];

export default function AcsFormInputs(props) {
  const [listLarge, setListLarge] = React.useState([]);
  const [siteList, setSiteList] = React.useState([]);

  const [trackingBool, setTrackingBool] = React.useState();
  const [count, setCount] = React.useState(1);
  const [projectList, setProjectList] = React.useState([]);

  const theme = useTheme();

  useEffect(() => {
    console.log(props.client);
    AcsService.getClientProjects(props.val, "")
      .then((resp) => {
        setProjectList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.val]);

  useEffect(() => {
    AcsService.getAllClients(props.client)
      .then((resp) => {
        console.log(resp.data);
        setListLarge(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.client]);

  useEffect(() => {
    if (props.client && props.project) {
      AcsService.getAllSites(
        props.client,
        props.project,
        props.formListHeader["site"]
      )
        .then((resp) => {
          console.log(resp.data);
          setSiteList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.project, props.formListHeader["site"]]);

  return (
    <Stack>
      <FormControl
        style={{
          marginTop: "35px",
        }}
      >
        <Paper style={{ padding: "10px" }} variant="outlined">
          <Stack direction="row" style={{ width: "100%" }}>
            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={props.client || ""}
              onInputChange={(e, newValue) => props.setClient(newValue)}
              options={listLarge}
              onChange={(e, newValue) => props.setVal(newValue)}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{
                margin: "10px",
                width: "100%",
              }}
              value={props.val}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  variant="filled"
                  label="Client"
                />
              )}
            />

<FormControl
  variant="filled"
  sx={{
    margin: "10px",
    width: "100%",
    pointerEvents: !props.client ? "none" : "auto",
  }}
  size="small"
>
  <InputLabel id="filter-id">Select Project</InputLabel>
  <Select
    labelId="filter-id"
    value={props.project}
    label="Filter"
    aria-disabled={!props.client}
    aria-describedby={!props.client ? "project-dependency" : null}
    onChange={(event) => {
      if (props.client) {
        props.setProject(event.target.value);
      }
    }}
    sx={{
      "& .MuiSelect-select": {
        opacity: !props.client ? 0.5 : 1, 
        backgroundColor: !props.client ? "rgba(0, 0, 0, 0.12)" : "inherit", 
      },
      "& .MuiInputLabel-root": {
        color: !props.client
          ? "rgba(0, 0, 0, 0.26)" 
          : theme.palette.mode === "light"
          ? "rgba(0, 0, 0, 0.54)"
          : "rgba(255, 255, 255, 0.7)",
        "&.Mui-focused": {
          color: "primary.main",
        },
      },
      "& .MuiFilledInput-root": {
        backgroundColor: !props.client ? "rgba(0, 0, 0, 0.12)" : "inherit", 
        "&:hover": {
          backgroundColor: !props.client ? "rgba(0, 0, 0, 0.12)" : "inherit",
        },
        "&.Mui-focused": {
          backgroundColor: !props.client ? "rgba(0, 0, 0, 0.12)" : "inherit",
        },
      },
    }}
  >
    {projectList.map((ele, index) => (
      <MenuItem key={index} value={ele}>
        {ele}
      </MenuItem>
    ))}
  </Select>
  
</FormControl>


            <TextField
              label={"Subject Code"}
              type="text"
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              size="small"
              value={props.subjectCode || ""}
              onChange={(e) => props.setSubjectCode(e.target.value)}
            />
            
<FormControl
  variant="filled"
  sx={{
    margin: "10px",
    width: "100%",
    pointerEvents: !props.project ? "none" : "auto",
  }}
  size="small"
  onSubmit={() => console.log("submit")}
>
  <TextField
    autoFocus={props.project ? true : false} 
    label="Tracking Number"
    type="text"
    variant="filled"
    size="small"
    value={props.trackingNumber}
    name="clientName"
    aria-disabled={!props.project}
    aria-describedby={!props.project ? "tracking-number-disabled-info" : null}
    onChange={(e) => {
      if (props.project) {
        props.setTrackingNumber(e.target.value);
      }
    }}
    
    onKeyDown={(e) => {
      if (!props.project && e.key !== "Tab") {
        e.preventDefault();
      }
    }}
    
    sx={{
      "& .MuiFilledInput-root": {
        opacity: !props.project ? 0.5 : 1, 
        backgroundColor: !props.project
          ? theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.25)" 
            : "rgba(255, 255, 255, 0.12)" 
          : "inherit",
        "&:hover": {
          backgroundColor: !props.project
            ? theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.12)"
              : "rgba(255, 255, 255, 0.12)"
            : "inherit",
        },
        "&.Mui-focused": {
          backgroundColor: !props.project
            ? theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.12)"
              : "rgba(255, 255, 255, 0.12)"
            : "inherit",
        },
      },
      "& .MuiInputLabel-root": {
        color: !props.project
          ? theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.46)" 
            : "rgba(255, 255, 255, 0.55)" 
          : theme.palette.mode === "light"
          ? "rgba(26, 25, 25, 0.64)" 
          : "rgba(255, 255, 255, 0.87)", 
        "&.Mui-focused": {
          color: props.project ? "primary.main" : theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.46)" : "rgba(255, 255, 255, 0.55)",
        },
        "&.MuiInputLabel-shrink": {
          
          color: !props.project
            ? theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.6)" 
              : "rgba(255, 255, 255, 0.9)" 
            : theme.palette.mode === "light"
            ? "rgba(26, 25, 25, 0.84)" 
            : "rgba(255, 255, 255, 0.95)", 
        },
      },
      "& .MuiFilledInput-input": {
        cursor: !props.project ? "not-allowed" : "text",
        color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.87)" : "inherit", 
      },
      "& .MuiInputBase-input::placeholder": {
        color: theme.palette.mode === "light"
          ? "rgba(0, 0, 0, 0.74)"
          : "rgba(255, 255, 255, 0.7)", 
        opacity: 1,
      },
    }}
    
  />
  
  {!props.project && (
    <span id="tracking-number-disabled-info" style={{ display: "none" }}>
      This field is disabled until a project is selected.
    </span>
  )}
</FormControl>

          </Stack>

          <Stack direction="row" style={{ width: "100%" }}>

<Autocomplete
  id="tags-standard"
  freeSolo
  inputValue={props.formListHeader["site"] || ""}
  onInputChange={(e, newValue) => {
    if (props.client && props.project) {
      props.handleHeaderChange("site", e.target.value);
    }
  }}
  options={siteList}
  onChange={(e, newValue) => {
    if (props.client && props.project) {
      props.handleSiteChange(newValue);
    }
  }}
  getOptionLabel={(option) =>
    typeof option === "string" || option instanceof String
      ? option
      : ""
  }
  style={{
    margin: "10px",
    width: "100%",
  }}
  sx={{
    pointerEvents: (!props.client || !props.project) ? "none" : "auto",
  }}
  aria-disabled={!props.client || !props.project}
  aria-describedby={!props.client || !props.project ? "site-dependency" : null}
  value={null}
  renderInput={(params) => (
    <TextField
      {...params}
      id="standard-multiline-flexible"
      size="small"
      type="text"
      variant="filled"
      label="Sites"
      inputProps={{
        ...params.inputProps,
        readOnly: !props.client || !props.project,
      }}
      sx={{
        "& .MuiInputLabel-root": {
          color: (!props.client || !props.project)
            ? theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.46)" 
              : "rgba(255, 255, 255, 0.55)" 
            : theme.palette.mode === "light"
            ? "rgba(26, 25, 25, 0.64)" 
            : "rgba(255, 255, 255, 0.87)", 
          "&.Mui-focused": {
            color: "primary.main", 
          },
        },
        "& .MuiFilledInput-root": {
          backgroundColor: (!props.client || !props.project)
            ? theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.12)" 
              : "rgba(255, 255, 255, 0.12)" 
            : "inherit",
          "&:hover": {
            backgroundColor: (!props.client || !props.project)
              ? theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.12)"
                : "rgba(255, 255, 255, 0.12)"
              : "inherit",
          },
          "&.Mui-focused": {
            backgroundColor: (!props.client || !props.project)
              ? theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.12)"
                : "rgba(255, 255, 255, 0.12)"
              : "inherit",
          },
        },
        "& .MuiInputBase-input": {
          opacity: (!props.client || !props.project) ? 0.5 : 1,
          color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.87)" : "inherit",
        },
        "& .MuiInputBase-input::placeholder": {
          color: theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.74)"
            : "rgba(255, 255, 255, 0.7)", 
          opacity: 1,
        },
      }}
    />
  )}
/>
            <TextField
              id="date"
              label="Date Received"
              type="date"
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              value={props.formListHeader["date_received"] || ""}
              onChange={(e) =>
                props.handleHeaderChange("date_received", e.target.value)
              }
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="time"
              label="Time Received"
              type="time"
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              value={props.formListHeader["time_received"] || ""}
              onChange={(e) =>
                props.handleHeaderChange("time_received", e.target.value)
              }
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Autocomplete
              id="tags-tz"
              freeSolo
              value={props.formListHeader["dt_received_timezone"]}
              options={timeZonesList}
              onChange={(e, new_value) =>
                props.handleHeaderChange("dt_received_timezone", new_value)
              }
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              sx={{
                margin: "10px",
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  required={true}
                  id="user-tz"
                  size="small"
                  type="text"
                  name={"user_timezone"}
                  label={"Select Timezone"}
                />
              )}
            />
            <TextField
              label={"Carrier"}
              select
              variant="filled"
              style={{
                textAlign: "center",
                color: "white",
                margin: "10px",
                width: "100%",
              }}
              size="small"
              // inputProps={{ className: classes.input }}
              // InputLabelProps={{ className: classes.inputLabel }}
              value={props.formListHeader["carrier"] || ""}
              onChange={(e) =>
                props.handleHeaderChange("carrier", e.target.value)
              }
            >
              {carrierTypes.map((option) => (
                <MenuItem key={option.value} value={option.value || ""}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Paper>
      </FormControl>
    </Stack>
  );
}
