import React, { useEffect } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import { Stack, Paper, Tooltip } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import BiService from "../services/BiService";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import LockResetIcon from "@mui/icons-material/LockReset";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useInterval } from "../components/helper/utils";
import CloseIcon from "@mui/icons-material/Close";

const ExportDownloads = ({ type }) => {
  const [downloads, setSetDownloads] = React.useState([]);
  const dispatch = useDispatch();

  const handleDownload = (file) => {
    BiService.getFile(file)
      .then((resp) => {
        console.log(resp.data);
        window.location.replace(resp.data);
        // setSetDownloads(resp.data)
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Download",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Download",
          })
        );
      });
  };
  const handleRefresh = (file) => {
    BiService.getDownloads(type)
      .then((resp) => {
        console.log(resp);
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useInterval(() => {
    BiService.getDownloads(type)
      .then((resp) => {
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000 * 8);

  useEffect(() => {
    BiService.getDownloads(type)
      .then((resp) => {
        // console.log(resp.data);
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box style={{ padding: "20px" }}>
      <Stack direction="row">
        <Typography
          variant="h1"
          component="div"
          color="secondary.main"
          role="button"
          tabIndex={0}
          aria-label="Export Downloads"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              // Add any click handler if needed
            }
          }}
          sx={{
            marginBottom: "8px",
            fontSize: "20.571px",
            lineHeight: "1.334",
            "&:focus": {
              outline: "2px solid #9b51e0",
              outlineOffset: "2px",
            },
          }}
        >
          Export Downloads
        </Typography>
        <Tooltip
          title="Refresh Downloads"
          style={{ cursor: "pointer" }}
          onClick={handleRefresh}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleRefresh();
            }
          }}
        >
          <IconButton
            aria-label="Refresh"
            color="primary"
            tabIndex={0}
            style={{
              marginTop: "0px",
              marginLeft: "10px",
              cursor: "pointer",
              marginBottom: "8px",
              padding: "0px",
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Stack>

      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="export downloads table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                Bucket
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                Client
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                Projects
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                Created On
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                File Name
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                Custom Export
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold" }}
                tabIndex={0}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {downloads.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  component="td"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`Bucket: ${row.bucket || "null"}`}
                  sx={{
                    "&:focus": {
                      outline: "2px solid #9b51e0",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  {row.bucket || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`Client: ${row.client || "null"}`}
                  sx={{
                    "&:focus": {
                      outline: "2px solid #9b51e0",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  {row.client || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`Projects: ${row.projects || "null"}`}
                  sx={{
                    "&:focus": {
                      outline: "2px solid #9b51e0",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  {row.projects || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`Created on: ${row.created_on || "null"}`}
                  sx={{
                    "&:focus": {
                      outline: "2px solid #9b51e0",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  {row.created_on || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`File name: ${row.file_name || "null"}`}
                  sx={{
                    "&:focus": {
                      outline: "2px solid #9b51e0",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  {row.file_name || "null"}
                </TableCell>
                <TableCell
                  align="center"
                  component="td"
                  tabIndex={0}
                  role="gridcell"
                  aria-label={`Custom Export: ${
                    row.is_custom_export ? "Yes" : "No"
                  }`}
                  sx={{
                    "&:focus": {
                      outline: "2px solid #9b51e0",
                      outlineOffset: "2px",
                    },
                  }}
                >
                  {row.is_custom_export ? (
                    <IconButton aria-label="Custom Export: Yes">
                      <CheckRoundedIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="Custom Export: No">
                      <ClearRoundedIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>

                <TableCell
                  align="center"
                  // onClick={(e) =>
                  //     handleDetailData(row)
                  // }
                >
                  {row.status === "SUCCESS" && (
                    <Tooltip
                      title="Ready to be Downloaded"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDownload(row.file_name)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleDownload(row.file_name);
                        }
                      }}
                      tabIndex={0}
                    >
                      <IconButton>
                        <SaveAltIcon color="green"></SaveAltIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {(row.status === "QUEUED" ||
                    row.status === "IN PROGRESS") && (
                    <Tooltip
                      title="File Still Loading"
                      style={{ cursor: "pointer" }}
                      tabIndex={0}
                    >
                      <IconButton>
                        <LockResetIcon color="yellow"></LockResetIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.status === "FAILED" && (
                    <Tooltip
                      title="File Export Failed"
                      style={{ cursor: "pointer" }}
                      tabIndex={0}
                    >
                      <IconButton>
                        <CloseIcon color="red"></CloseIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExportDownloads;
