import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import UIWrapper from "../../components/UIWrapper";
import ClientsTable from "./ClientsTable";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";

const onhold_get_url = "/client-onboarding/onhold-clients";
const sampled_get_url = "/client-onboarding/clients";
const ruid_get_url = "/client-onboarding/ruids";
const bc_container_get_url = "/client-onboarding/bc-containers";
const lv_container_types_get_url = "/client-onboarding/lv-container-types";
const material_get_url = "/client-onboarding/material";

const onhold_update_url = "/client-onboarding/onhold-client/edit";
const sampled_update_url = "/client-onboarding/client/edit";
const ruid_update_url = "/client-onboarding/ruid/edit";
const bc_container_update_url = "/client-onboarding/bc-container/edit";
const lv_container_types_update_url =
  "/client-onboarding/lv-container-type/edit";
const material_update_url = "/client-onboarding/material/edit";

const onhold_create_url = "/client-onboarding/onhold-client/create";
const sampled_create_url = "/client-onboarding/client/create";
const ruid_create_url = "/client-onboarding/ruid/create";
const bc_container_create_url = "/client-onboarding/bc-container/create";
const lv_container_types_create_url =
  "/client-onboarding/lv-container-type/create";
const material_create_url = "/client-onboarding/material/create";

const onhold_newrow = { client: "", poc_email: "" };
const sampled_newrow = { client: "", host_name: "", source: "" };
const ruid_newrow = { client: "", ruid_pattern: "" };
const bc_container_newrow = { material: "", supplier: "", catalog: "" };
const iv_container_type_newrow = { material: "", supplier: "", catalog: "" };
const material_newrow = { source: "", source_name: "" };

const ManageClients = () => {
  const [value, setValue] = useState("1");

  const ruid_columns = [
    {
      field: "client",
      flex: 1,
      headerName: "Client",
      editable: true,
    },
    {
      field: "ruid_pattern",
      headerName: "RUID Pattern",
      flex: 1,
      editable: true,
    },
  ];

  const onhold_columns = [
    {
      field: "client",
      flex: 1,
      headerName: "Client",
      // preProcessEditCellProps: (params) => {
      //   const hasError = params.props.value.trim() === "";
      //   return { ...params.props, error: hasError };
      // },
      //   minWidth: 180,
      editable: true,
    },
    {
      field: "poc_email",
      headerName: "Point of Contact",
      flex: 1,
      editable: true,
    },
  ];

  const sampled_columns = [
    {
      field: "client",
      flex: 1,
      headerName: "Client",
      //   minWidth: 180,
      editable: true,
    },
    {
      field: "host_name",
      headerName: "Host Name",
      flex: 1,
      editable: true,
    },
    {
      field: "source",
      headerName: "Source",
      width: 100,
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueGetter: (params) => {
        // if (!params.value) {
        //   return "";
        // }
        // return params.value;
        return params ?? "";
      },
      valueOptions: ["LabVantage", "StarLIMS"],
    },
  ];

  const bc_container_columns = [
    {
      field: "material",
      flex: 1,
      headerName: "Material",
      editable: true,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 1,
      editable: true,
    },
    {
      field: "catalog",
      headerName: "Catalog",
      flex: 1,
      editable: true,
    },
  ];

  const iv_container_type_columns = [
    {
      field: "material",
      flex: 1,
      headerName: "Material",
      editable: true,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 1,
      editable: true,
    },
    {
      field: "catalog",
      headerName: "Catalog",
      flex: 1,
      editable: true,
    },
  ];

  const material_columns = [
    {
      field: "source",
      flex: 1,
      headerName: "Source",
      editable: true,
    },
    {
      field: "source_name",
      headerName: "Source Name",
      flex: 1,
      editable: true,
    },
  ];

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <UIWrapper title="Manage Client Details" paper>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="RUID Patterns" value="1" />
              <Tab label="OnHold Clients" value="2" />
              <Tab label="SampledSphere Clients" value="3" />
              <Tab label="BC Container" value="4" />
              <Tab label="IV Container Type" value="5" />
              <Tab label="Material" value="6" />
            </TabList>
          </Box>

          <TabPanel sx={{ padding: "10px 0" }} value="1">
            <Grid item sm={12} md={6} lg={3.5} height="600px">
              <ClientsTable
                tableName="RUID Patterns"
                isRuidTable={true}
                staticColumns={ruid_columns}
                saveUrl={ruid_update_url}
                initialValue={ruid_newrow}
                createUrl={ruid_create_url}
                getUrl={ruid_get_url}
              />
            </Grid>
          </TabPanel>

          <TabPanel sx={{ padding: "10px 0" }} value="2">
            <Grid item sm={12} md={6} lg={3.5} height="600px">
              <ClientsTable
                tableName="OnHold Clients"
                isRuidTable={false}
                staticColumns={onhold_columns}
                saveUrl={onhold_update_url}
                initialValue={onhold_newrow}
                getUrl={onhold_get_url}
                createUrl={onhold_create_url}
              />
            </Grid>
          </TabPanel>

          <TabPanel sx={{ padding: "10px 0" }} value="3">
            <Grid item xs={12} md={12} lg={5} height="600px">
              <ClientsTable
                tableName="SampledSphere Clients"
                isRuidTable={false}
                staticColumns={sampled_columns}
                initialValue={sampled_newrow}
                saveUrl={sampled_update_url}
                createUrl={sampled_create_url}
                getUrl={sampled_get_url}
              />
            </Grid>
          </TabPanel>

          <TabPanel sx={{ padding: "10px 0" }} value="4">
            <Grid item xs={12} md={12} lg={4} height="600px">
              <ClientsTable
                tableName="BC Container"
                isRuidTable={false}
                staticColumns={bc_container_columns}
                initialValue={bc_container_newrow}
                saveUrl={bc_container_update_url}
                createUrl={bc_container_create_url}
                getUrl={bc_container_get_url}
              />
            </Grid>
          </TabPanel>

          <TabPanel sx={{ padding: "10px 0" }} value="5">
            <Grid item xs={12} md={12} lg={4} height="600px">
              <ClientsTable
                tableName="IV Container Type"
                isRuidTable={false}
                staticColumns={iv_container_type_columns}
                initialValue={iv_container_type_newrow}
                saveUrl={lv_container_types_update_url}
                createUrl={lv_container_types_create_url}
                getUrl={lv_container_types_get_url}
              />
            </Grid>
          </TabPanel>

          <TabPanel sx={{ padding: "10px 0" }} value="6">
            <Grid item xs={12} md={12} lg={4} height="600px">
              <ClientsTable
                tableName="Material"
                isRuidTable={false}
                staticColumns={material_columns}
                initialValue={material_newrow}
                saveUrl={material_update_url}
                createUrl={material_create_url}
                getUrl={material_get_url}
              />
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </UIWrapper>
  );
};

export default ManageClients;
