import { useEffect, useState, useRef } from "react";
import {
  TextField,
  Stack,
  Button,
  Paper,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../mui";
import BiotechIcon from "@mui/icons-material/Biotech";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { JsonForms } from "@jsonforms/react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import AcsService from "../../services/AcsService";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

// Custom Renderer Implementation
const CustomInputRenderer = (props) => {
  const {
    data,
    description,
    id,
    enabled,
    error,
    label,
    path,
    handleChange,
    required,
  } = props;

  const inputRef = useRef(null);
  //const [errors, setErrors] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Effect to focus the input when there's an error
  useEffect(() => {
    if (error && inputRef.current) {
      inputRef.current.focus();
    }
  }, [error]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const inputId = label
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-|-$/g, "");
  console.log(inputId);

  return (
    <FormControl error={error} fullWidth required sx={{ mb: 2 }}>
      <TextField
        inputRef={inputRef}
        id={"my-label"} // Use dynamic inputId
        label={isFocused || data ? label : ""} // Use dynamic label
        value={data || ""}
        onChange={(ev) => handleChange(path, ev.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={!enabled}
        error={error}
        fullWidth
        variant="standard"
        size="small"
        placeholder={isFocused || data ? "" : label}
        //style={{ marginTop: "15px" }}
        InputLabelProps={{
          htmlFor: "my-label",
          sx: {
            "&.Mui-focused": {
              color: "primary.main",
            },
          },
        }}
        sx={{
          marginTop: "15px",
          "& .MuiInputBase-root": {
            marginTop: "0px",
          },
          "& .MuiFormLabel-root": {
            top: "-14px !important",
          },
        }}
        inputProps={{
          "aria-describedby": description
            ? `${inputId}-description`
            : undefined,
          "aria-required": required,
          "aria-invalid": error ? "true" : "false",
          "aria-labelledby": `${inputId}-label`, // Correct association
        }}
      />
      {description && (
        <FormHelperText id={`${inputId}-description`}>
          {description}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText error id={`${inputId}-error`}>
          This field is required
        </FormHelperText>
      )}
    </FormControl>
  );
};

// Tester for the custom renderer
const customRendererTester = (uischema, schema) => {
  if (uischema.type === "Control") {
    return 3;
  }
  return -1;
};

const CustomInputRendererWithProps =
  withJsonFormsControlProps(CustomInputRenderer);

export default function AcsSamples(props) {
  const [containerList, setContainerList] = useState([]);
  let removeFormFields = (i) => {
    let newFormValues = [...props.formValues];
    newFormValues.splice(i, 1);
    props.setFormValues(newFormValues);
  };

  let handleChange = (i, data) => {
    if (data) {
      let newFormValues = props.formValues;
      newFormValues[i].data = data.data;
      props.setFormValues(newFormValues);
    }
  };

  let handleInvChange = (i, e) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i].inventory_code_input = e.target.value;
    props.setFormValues(newFormValues);
  };
  let handleSelectChange = (i, e, changeDetail) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i][changeDetail] = e.target.value;
    props.setFormValues(newFormValues);
  };
  let handleSelectOptionChange = (i, newVal, changeDetail) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i][changeDetail] = newVal;
    props.setFormValues(newFormValues);
  };
  let handleAddInv = (i, e) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValues));
    newFormValues[i]["inventory_code"] = e;
    newFormValues[i]["inventory_code_input"] = "";
    props.setFormValues(newFormValues);
  };
  useEffect(() => {
    AcsService.getContainers(props.client)
      .then((resp) => {
        setContainerList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Paper
      style={{
        margin: "35px 0px",
        paddingBottom: "20px",
        display: props.activeStep === 2 ? "block" : "none",
        background: "#eaeaf0",
      }}
      elevation={0}
      variant="outlined"
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          margin: "16px 20px;",
        }}
      >
        <Typography
          role="status"
          tabIndex={0}
          aria-hidden="false"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark" ? "#000000" : "#000000",
            height: "100%",
            fontWeight: 500,
            "&:focus": {
              outline: (theme) => `2px solid ${theme.palette.primary.main}`,
              outlineOffset: "2px",
            },
          }}
        >
          Scan Samples:
        </Typography>
        <Button
          variant="contained"
          onClick={() => props.addFormFields()}
          endIcon={<BiotechIcon />}
        >
          Add Samples
        </Button>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        style={{ overflow: "auto" }}
        alignItems="center"
        justifyContent="center"
      >
        {props.formValues.map((option, index) => (
          <Paper
            style={{
              padding: "10px",
              width: "95%",
              paddingBottom: "25px",
            }}
            key={index}
            // variant="outlined"
            elevation={2}
          >
            <Stack direction="row" style={{ width: "100%" }}>
              <Stack direction="column" style={{ width: "100%" }}>
                <Stack direction="row" justifyContent="flex-end" width="100%">
                  <IconButton
                    aria-label="Remove form field"
                    tabIndex={0}
                    onClick={() => removeFormFields(index)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        removeFormFields(index);
                      }
                    }}
                    sx={{
                      color: "#EE3263",
                      padding: "5px",
                      "&:focus": {
                        outline: (theme) =>
                          `2px solid ${theme.palette.primary.main}`,
                        outlineOffset: "2px",
                      },
                    }}
                  >
                    <CancelPresentationIcon fontSize="medium" />
                  </IconButton>
                </Stack>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  freeSolo
                  inputValue={option.inventory_code_input || ""}
                  onInputChange={(e) => handleInvChange(index, e)}
                  options={[]}
                  onChange={(e, newValue) => handleAddInv(index, newValue)}
                  getOptionLabel={(option) =>
                    typeof option === "string" || option instanceof String
                      ? option
                      : ""
                  }
                  value={option.inventory_code}
                  style={{ width: "100%", padding: "5px" }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                        aria-label={`Inventory code ${option}`}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (
                            e.key === "Delete" ||
                            e.key === "Backspace" ||
                            e.key === "Enter"
                          ) {
                            e.preventDefault();
                            const currentCodes = Array.isArray(
                              option.inventory_code
                            )
                              ? option.inventory_code
                              : [];
                            const newValue = [...currentCodes];
                            newValue.splice(index, 1);
                            handleAddInv(index, newValue);
                          }
                        }}
                        deleteIcon={
                          <CloseIcon fontSize="small" aria-hidden="true" />
                        }
                        onDelete={(e) => {
                          const currentCodes = Array.isArray(
                            option.inventory_code
                          )
                            ? option.inventory_code
                            : [];
                          const newValue = [...currentCodes];
                          newValue.splice(index, 1);
                          handleAddInv(index, newValue);
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      id="standard-multiline-flexible"
                      size="small"
                      type="text"
                      name={"inventory_code_input"}
                      variant="standard"
                      label={"Inventory Code"}
                    />
                  )}
                />

                <Stack direction="row" style={{ width: "100%" }}>
                  <Autocomplete
                    id={`source-autocomplete-${index}`}
                    value={option.source || ""}
                    options={props.sourceList}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(index, newValue, "source")
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"source"}
                        label={"Source"}
                        sx={{
                          "& .MuiInputBase-root": {
                            paddingRight: "5px !important",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={(e) => {
                                const input = e.currentTarget
                                  .closest(".MuiAutocomplete-root")
                                  .querySelector("input");
                                input.click();
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "Enter" ||
                                  e.key === " " ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                  const input = e.currentTarget
                                    .closest(".MuiAutocomplete-root")
                                    .querySelector("input");
                                  input.click();
                                }
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                              aria-label="Open source dropdown"
                            >
                              <ArrowDropDown />
                            </div>
                          ),
                        }}
                      />
                    )}
                    ListboxProps={{
                      "aria-label": "Source options",
                      role: "listbox",
                    }}
                  />

                  <Autocomplete
                    id="tags-standard"
                    value={option["container_type"] || ""}
                    options={containerList}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(
                        index,
                        newValue,
                        "container_type"
                      )
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Container Type"}
                        label={"Container Type"}
                        sx={{
                          "& .MuiInputBase-root": {
                            paddingRight: "5px !important",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={(e) => {
                                const input = e.currentTarget
                                  .closest(".MuiAutocomplete-root")
                                  .querySelector("input");
                                input.click();
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "Enter" ||
                                  e.key === " " ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                  const input = e.currentTarget
                                    .closest(".MuiAutocomplete-root")
                                    .querySelector("input");
                                  input.click();
                                }
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                              aria-label="Open source dropdown"
                            >
                              <ArrowDropDown />
                            </div>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>

                <JsonForms
                  schema={props.sampleInputs}
                  uischema={{
                    ...props.sampleSchema,
                    options: {
                      ...props.sampleSchema?.options,
                      showUnfocusedDescription: true,
                    },
                  }}
                  data={option["data"]}
                  key={index}
                  renderers={[
                    ...materialRenderers,
                    {
                      tester: customRendererTester,
                      renderer: CustomInputRendererWithProps,
                    },
                  ]}
                  cells={materialCells}
                  onChange={(data) => handleChange(index, data)}
                  aria-live="polite"
                  aria-atomic="true"
                  config={{
                    restrict: false,
                    showUnfocusedDescription: true,
                    requireAll: false,
                    focusable: true,
                    input: {
                      props: {
                        tabIndex: 0,
                        role: "textbox",
                      },
                    },
                  }}
                />
              </Stack>

              {/* <IconButton
                aria-label="Remove form field"
                tabIndex={0}
                onClick={() => removeFormFields(index)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                    removeFormFields(index);
                  }
                }}
                sx={{
                  color: "#EE3263",
                  padding: "5px",
                  "&:focus": {
                    outline: (theme) =>
                      `2px solid ${theme.palette.primary.main}`,
                    outlineOffset: "2px",
                  },
                }}
              >
                <CancelPresentationIcon fontSize="medium" />
              </IconButton> */}
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Paper>
  );
}
