import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export default function AcsNav(props) {
  const theme = useTheme();

  React.useEffect(() => {
    const dotsContainer = document.querySelector(".MuiMobileStepper-dots");
    if (dotsContainer) {
      dotsContainer.setAttribute("aria-hidden", "true");
      dotsContainer.setAttribute("tabIndex", "-1"); 
    }
  }, []);
 
  const handleKeyDown = (event, action) => {
    if (event.key === "Enter" || event.key === " ") {
      action(); 
    }
  };

  return (
    <MobileStepper
      variant="dots"
      steps={4}
      position="static"
      activeStep={props.activeStep}
      //aria-hidden= "true"
      sx={{ width: "100%", flexGrow: 1, padding: "0px"}}
      nextButton={
        props.activeStep !== 5 ? (
          
          <Button
  size="small"
  onClick={(e) => {
    const isDisabled = !(
      props.activeStep !== 3 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      props.formListHeader.carrier &&
      props.formListHeader.date_received &&
      props.formListHeader.dt_received_timezone
    );
    if (isDisabled) {
      e.preventDefault();
      return;
    }
    props.handleNext();
  }}
  onKeyDown={(e) => {
    const isDisabled = !(
      props.activeStep !== 3 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      props.formListHeader.carrier &&
      props.formListHeader.date_received &&
      props.formListHeader.dt_received_timezone
    );
    if (isDisabled) {
      return;
    }
    handleKeyDown(e, props.handleNext);
  }}
  aria-disabled={
    !(props.activeStep !== 3 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      props.formListHeader.carrier &&
      props.formListHeader.date_received &&
      props.formListHeader.dt_received_timezone)
  }
  role="button"
  tabIndex={0}
  aria-label={
    !(props.activeStep !== 3 &&
      props.client &&
      props.project &&
      props.trackingNumber &&
      props.formListHeader.site &&
      props.formListHeader.carrier &&
      props.formListHeader.date_received &&
      props.formListHeader.dt_received_timezone)
      ? "Next button - disabled until all required fields are filled"
      : "Go to next step"
  }
  sx={{
    '&[aria-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.7,
      cursor: 'not-allowed',
      color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.56)', 
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.12)',
    },
    '&:not([aria-disabled="true"])': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '2px',
    },
    '&:focus:not(:focus-visible)': {
      outline: 'none'
    },
    '&.Mui-focusVisible': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '2px',
    }
  }}
>
  Next
  {theme.direction === "rtl" ? (
    <KeyboardArrowLeft />
  ) : (
    <KeyboardArrowRight />
  )}
</Button>
          
        ) : (
          <Button
            size="small"
            onClick={props.onScanningComplete}
            onKeyDown={(e) => handleKeyDown(e, props.onScanningComplete)}
            disabled={
              props.client &&
              props.project &&
              props.trackingNumber &&
              props.formListHeader.site &&
              props.formListHeader.carrier
                ? false
                : true
            }
            tabIndex="0"
          >
            Done
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        )
      }
      backButton={ 
        <Button
  size="small"
  onClick={(e) => {
    if (props.activeStep === 0) {
      e.preventDefault();
      return;
    }
    props.handleBack();
  }}
  onKeyDown={(e) => {
    if (props.activeStep === 0) {
      return;
    }
    handleKeyDown(e, props.handleBack);
  }}
  aria-disabled={props.activeStep === 0}
  role="button"
  tabIndex={0}
  aria-label={
    props.activeStep === 0
      ? "Back button - disabled at first step"
      : "Go back to previous step"
  }
  sx={{
    
    '&[aria-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.7,
      cursor: 'not-allowed',
      color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.56)', 
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.12)',
    },
    '&:not([aria-disabled="true"])': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '2px',
    },
    '&:focus:not(:focus-visible)': {
      outline: 'none'
    },
    '&.Mui-focusVisible': {
      outline: '2px solid',
      outlineColor: 'primary.main',
      outlineOffset: '2px',
    }
  }}
>
  {theme.direction === "rtl" ? (
    <KeyboardArrowRight />
  ) : (
    <KeyboardArrowLeft />
  )}
  Back
</Button>
      }
    />
  );
}


