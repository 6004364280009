import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import BiService from "../services/BiService";

const AcsCustomExport = ({ setValue }) => {
  const [profile, setProfile] = useState("");
  const [client, setClient] = useState("");
  const theme = useTheme();

  const [profileOptions, setProfileOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

  const [details, setDetails] = useState([]);
  const dispatch = useDispatch();
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  // console.log("up", userProfile);

  // console.log("sdsafj", clientOptions);

  const handleClient = (client) => {
    setClient(client);
    setProfile("");
    let prof = {};
    if (details.length !== 0) prof = details.find((el) => el[0] === client);
    if (prof && prof[1] && prof[1].length !== 0)
      setProfileOptions(prof[1].map((el) => el["profile"]));
    // console.log(prof["config_params"].map((el) => el["profile"]));
  };

  useEffect(() => {
    // let list = [];
    // details?.forEach((ele) => {
    //   if (ele.client) list.push(ele.client);
    // });
    // setClientOptions(list);

    BiService.getCustomExportProfiles()
      .then((resp) => {
        // console.log("data", resp.data);
        let list = [];
        resp.data?.forEach((ele) => {
          if (ele[0]) list.push(ele[0]);
        });
        setClientOptions(list);
        setDetails(resp.data);
        console.log("ceprf", resp.data);
        if (list.length === 0) {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: `Client: ${userProfile.group}  does not have custom data export functionality.`,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        // dispatch(
        //   snackbarActions.showNotification({
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: err.response.data.detail,
        //   })
        // );
      });
  }, []);

  const onSubmit = () => {
    BiService.doCustomExport({ client, profile })
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setValue("two");
      })
      .catch((error) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.data.detail,
          })
        );
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        xs={12}
        mt={2}
      >
        <Typography
          variant="h2"
          component="div"
          role="button"
          color={theme.palette.mode == "light" ? "secondary.main" : "#c9d1d9"}
          sx={{ mt: 2, mb: 1, fontSize: "1.25rem", marginBottom: 0 }}
          tabIndex={0}
        >
          Select a Client and a Profile for Full Custom Data Export
        </Typography>
      </Grid>
      <Grid item md={12} container alignItems="center" justifyContent="center">
        <FormContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Typography>{"Select a Client & profile"}</Typography> */}
            {/* <Divider orientation="horizontal" sx={{ width: "100%" }} /> */}
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              size="small"
              margin="dense"
              options={clientOptions}
              onChange={(e, new_value) => handleClient(new_value)}
              renderInput={(params) => (
                <TextField margin="dense" {...params} label="Client" />
              )}
              value={client}
              sx={{ minWidth: 200 }}
            />
            <Autocomplete
              id="tags-standard"
              freeSolo
              inputValue={profile || ""}
              aria-disabled={!client}
              options={client ? profileOptions : []}
              onChange={(e, new_value) => {
                if (!client) return;
                setProfile(new_value);
              }}
              getOptionLabel={(option) =>
                typeof option === "string" || option instanceof String
                  ? option
                  : ""
              }
              style={{ width: "100%", margin: "15px 5px 5px 5px" }}
              renderInput={(params) => (
                <TextField
                  {...params}

                  id="standard-multiline-flexible"
                  size="small"
                  type="text"
                  name={"Profile"}
                  label={"Profile"}

                  sx={{
                    "& .MuiFormControl-root": {
                      outline: "none !important",
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
                      "&.Mui-focused": {
                        color: 'primary.main'
                      }
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.54)" : "rgba(255, 255, 255, 0.7)",
                      opacity: 1,
                    },
                    // Add styles for disabled state
                    "&[aria-disabled='true']": {
                      backgroundColor: 'rgba(0, 0, 0, 0.12)',
                      "& .MuiInputBase-root": {
                        color: 'rgba(0, 0, 0, 0.26)',
                        borderColor: 'rgba(0, 0, 0, 0.12)',

                      },
                      "& .MuiInputLabel-root": {
                        color: 'rgba(0, 0, 0, 0.26)',
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: 'rgba(0, 0, 0, 0.12)',
                      }
                    },
                    // Add styles for focused state
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: 'primary.main',
                      borderWidth: 2,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disabled: !client
                  }}
                />
              )}
            />

          </Box>
        </FormContainer>
      </Grid>
      <Grid item xs={24} container justifyContent="center">
        <Button
          aria-disabled={!client || !profile}
          type="submit"
          role="button"
          tabIndex={0}
          onClick={(e) => {
            if (!client || !profile) {
              e.preventDefault();
              return;
            }
            onSubmit();
          }}

          sx={{

            '&[aria-disabled="true"]': {
              pointerEvents: 'none',
              opacity: 0.7,
              cursor: 'not-allowed',
              color: 'rgba(0, 0, 0, 0.26)',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              color: theme.palette.mode === "dark" ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.26)'

            },
            '&:not([aria-disabled="true"])': {
              cursor: 'pointer',
            },
            '&:focus': {
              outline: '2px solid',
              outlineColor: 'primary.main',
              outlineOffset: '2px',
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none'
            },
            '&.Mui-focusVisible': {
              outline: '2px solid',
              outlineColor: 'primary.main',
              outlineOffset: '2px',
            }
          }}
        >
          CUSTOM EXPORT
        </Button>

      </Grid>
    </Grid>
  );
};

export default AcsCustomExport;
